// @todo: move other ActionTypes here, that are currently defined in the action-files
// to have a better overview, because: ActionTypes must be unique!

import { OIDCRequestScopes } from "@modules/oidc/src"


/**
 * All existing EntityTypes, enumerated
 */
export enum EntityType {
  // #region action request related EntityTypes
  ActionRequest = "actionRequest",
  // #endregion

  // #region Program related EntityTypes
  Category = "categories",
  Program = "program",
  // #endregion

  // #region Challenge related EntityTypes
  // @todo multi: Fund = "fund", @see https://futureprojects.atlassian.net/browse/FCP-1432
  Challenge = "challenge", // @todo multi: wird zu BasicChallenge? @see https://futureprojects.atlassian.net/browse/FCP-1432
  ChallengeConcretization = "challengeConcretization",
  Proposal = "proposal",
  ProposalAttachment = "proposalAttachment",
  AttachmentDefinition = "attachmentDefinition",
  // #endregion

  // #region Feedback related EntityTypes
  Discussion = "discussion",
  FeedbackInvitation = "feedbackInvitation",
  FeedbackPost = "feedbackPost",
  // #endregion

  // #region Idea related EntityTypes
  Idea = "idea",
  // #endregion

  // #region project related EntityTypes
  Project = "project",
  ProjectFollowership = "projectFollowership",
  /** @deprecated replaced by UserObjectRoles, delete when onboarding is migrated. */
  ProjectMembership = "projectMembership",
  SupportRequest = "supportRequest",
  TeamUpload = "teamUpload",
  // #endregion

  // #region document
  Document = "document",
  Chapter = "chapter",
  // #endregion

  // #region Platform related EntityTypes
  Provider = "provider",
  // @todo multi: Tenant = "tenant",
  // @see https://futureprojects.atlassian.net/browse/FCP-1540
  User = "user",
  UserObjectRole = "userObjectRole"
  // @todo multi: AccountManager, ChallengeManager, …, AbstractUserChallengeRole, …?
  // @see https://futureprojects.atlassian.net/browse/FCP-1539
  // #endregion
}

// #region define endpoint function types

/** EntityTypes that are not loadable as "standalone", but are delivered pickaback ("huckepack") with other entities */
type NotLoadableEntityTypes = EntityType.ProjectMembership | EntityType.ProjectFollowership | EntityType.ChallengeConcretization
export type LoadableEntityType = Exclude<EntityType, NotLoadableEntityTypes>

/** EntityTypes that are not createable */
type NotCreateableEntityTypes = EntityType.UserObjectRole | EntityType.ActionRequest
export type CreateableEntityType = Exclude<EntityType, NotCreateableEntityTypes>

/** EntityTypes that are not updateable */
type NotUpdateableEntityTypes = EntityType.ProjectFollowership | EntityType.UserObjectRole | EntityType.ActionRequest
export type UpdateableEntityType = Exclude<EntityType, NotUpdateableEntityTypes>

/** EntityTypes that are not deleteable */
type NotDeleteableEntityTypes = EntityType.Provider | EntityType.UserObjectRole | EntityType.ActionRequest
export type DeleteableEntityType = Exclude<EntityType, NotDeleteableEntityTypes>

// #endregion

/**
 * An IEntityTypeReference is an interface that references an EntityType by its typed name.
 */
export interface IEntityTypeReference<E extends EntityType> {
  entityType: E // identifier for an EntityType
}

/**
 * EntityTypes that are crippled when fetched as collection and therefor using the detailResult property.
 * Is mainly used in tests to create similar entity data as the backend does.
 *
 * Must be synchronized to the backend behaviour.
 *
 * @see backend: /doc/api.md
 */
export const DetailResultEntityTypes: EntityType[] = [
  EntityType.Project,
  EntityType.Challenge,
  EntityType.Program,
  EntityType.Proposal,
  EntityType.User,

  /*
  // @todo multi
  EntityType.Provider,
  EntityType.Tenant,
  EntityType.Idea,
  EntityType.Fund,
 */
]

/**
 * Available global statistics types, e.g. /programs/statistics.
 */
export enum GlobalStatisticsType {
  Challenge = "challenge",
  Ideas = "idea",
  Project = "project",
  Program = "program",
  Tenant = "tenant",
  User = "user",
}

/**
 * Available single statistics types, e.g. /programs/1/statistics.
 */
export enum SingleStatisticsType {
  Program = "single_program",
}

/**
 * Union type of all available statistic types.
 *
 * @todo multi most probably unneccessary, we won't mix global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
export type StatisticsType = GlobalStatisticsType | SingleStatisticsType

/**
 * Checks, if statisticsType is a SingleStatisticsType.
 *
 * @todo multi is unnecessary with split into global vs single -> https://futureprojects.atlassian.net/browse/FCP-1703
 * @deprecated
 */
export const isSingleStatisticType = (statisticsType: StatisticsType): statisticsType is SingleStatisticsType =>
  // @todo multi reinsert following condition, but causes circular imports (client.ts <=> reduxTypes.ts)
  // statisticsEndpointList[statisticsType].url.includes("/[id]/")
  // &&
  Object.values(SingleStatisticsType).includes(statisticsType as SingleStatisticsType)

/**
 * types that represent an option to upload a file
 *
 * to add new UploadTypes the uploadFileSaga must be extended to trigger the (re)load of depending entities
 * e.g. if a concretization image was uploaded, the corresponding IFund/IChallenge should be (re)freshed
 */
export enum UploadType {
  ConcretizationImage = "concretization_image",
  ChallengeLogo = "challenge_logo",
  ProcessLogo = "process_logo",
  ProjectPicture = "project_picture",
  ProjectVisualization = "project_visualization",
  UserPicture = "user_picture",

  // uploads connected to complex EntityTypes
  // ApplicationAttachment = "application_attachment",
  // TeamUpload = "team_upload",
}

/**
 * Types for general api actions
 */
export enum GeneralApiActionTypes {
  // EntityType related actions that are sent from Application level,
  // and trigger 'generalSaga`s that update state.data (scopedEntityReducer) and allow tracking of usecase-specific request status (via entityUsecaseReducer).
  Load = "_LOAD_ENTITY_",
  LoadCollection = "_LOAD_ENTITY_COLLECTION_",
  LoadCollectionPage = "_LOAD_ENTITY_COLLECTION_PAGE_",
  Create = "_CREATE_ENTITY_",
  Update = "_UPDATE_ENTITY_",
  Delete = "_DELETE_ENTITY_",
  Upload = "_UPLOAD_FILE_",

  // model "success" actions that are used by scopedEntityReducer (for state.data), entityUsecaseReducer (to cleanup state.entityUsecases), and maybe specific reducers
  LoadSuccess = "_LOAD_SUCCESS_",
  LoadCollectionSuccess = "_LOAD_COLLECTION_SUCCESS_",
  CreateSuccess = "_CREATE_ENTITY_SUCCESS_",
  UpdateSuccess = "_UPDATE_ENTITY_SUCCESS_",
  DeleteSuccess = "_DELETE_ENTITY_SUCCESS_",

  // tracking of request state
  NewUsecaseRequestRunning = "_NEW_USECASE_RUNNING_",
  NewUsecaseRequestSuccess = "_NEW_USECASE_SUCCESS_",

  // statistics
  LoadStatistics = "_LOAD_STATISTICS_",
  LoadStatisticsRunning = "_LOAD_STATISTICS_RUNNING_",
  LoadStatisticsSuccess = "_LOAD_STATISTICS_SUCCESS_",
}

/**
 * ActionTypes are combined from diverse general and specific types of actions
 */
export const ActionTypes = { ...GeneralApiActionTypes /* , ...AndereActionTypes */ }
export type ActionType = typeof ActionTypes

/**
 * Enumeration of available ScopeTypes/ registered use cases:
 * a ScopeType defines a kind of operation, to be used on a special entity type or some action connected to an entity
 *
 * The identifiers content must match to the EntityTypes!!
 */
export enum ScopeTypes {

  /** used as default usecaseKey for uploading a file in diverse situations (logos, images, ...) */
  UploadFileOperation = "_usecase_upload_file_operation",

  // general scopetypes are created by combining EntityType + action, e.g. project + _operation

  // specific ScopeTypes
  // NOTE no pages/component listens to state.requests of this ScopeType
  // it's only here b/c activateFeedbackInvitationSaga sadly sends those messages that no-one will ever hear ...
  FeedbackInvitationOperation = "feedbackInvitation_operation",
  ProposalOperation = "proposal_operation",
  UserOperation = "user_operation", // still used for all not-yet-migrated user operations in sagas: currentUser, registration, users
  VerificationOperation = "verification_operation",

  // #region specific scope types for module OIDC
  // OIDC scope types must be integrated in the global `ScopeTypes` enum
  // because we use `scopedRequestReducer` and its param type is `ScopeType`.
  // @todo oauth maybe there's another way?
  OIDCLoadProviders = OIDCRequestScopes.LoadOIDCProviders,
  OIDCFetchToken = OIDCRequestScopes.FetchOIDCToken,
  OIDCLoginWithToken = OIDCRequestScopes.LoginWithOIDCToken,
  // #endregion
}