import { createSelector } from "@reduxjs/toolkit"

import { INumericIdentifierModel, ISlugAndNumericIdentifierModel } from "@api/schema"
import { LoadableEntityType } from "@redux/common/reduxTypes"
import { AppState } from "@redux/reducer"

import { IIndexedCollectionState } from "./reducers"


/**
 * selectCollection is a help-function, that filters from the given state all Objects with a given type
 * (e.G. EntityType.Challenge) and returns them as an array of a given ScopedModel (e.G. <IChallenge>)
 * usage example, mostly in pages:
 * const mapStateToProps = (state: AppState) => ({
 * challenges: selectCollection<IChallenge>(state, EntityType.Challenge),
 * })
 *
 * If a typisation error occures here, after a new EntityType was added, the new EntityType must
 * get a reducer in the scopedObjectsCombinedReducer!
 */
export const selectCollection = <T extends INumericIdentifierModel>(state: AppState, scope: LoadableEntityType): T[] =>
  Object.values(state.data[scope] as IIndexedCollectionState<T>) as T[]

/**
 * Extended version of selectCollection: it additionally filters those Objects, which ids are given
 * in a separate id-array.
 *
 * Uses createSelector to make sure, no re-rendering occur, b/c without createSelector
 * the filtering would create a new object on every call and cause re-rendering of the calling component.
 *
 * @param state the current AppState, usually provided by useSelector()
 * @param scope EntityType for which the state should be filtered to produce a cut-out of it.
 * @param ids array of ids for which the state cut-out should be filtered
 */
export const selectCollectionByIds
  : <T extends INumericIdentifierModel>(state: AppState, scope: LoadableEntityType, ids: number[]) => T[]
  = createSelector<
    // Typisation for createSelector:
    // An array of functions: as many functions as the output selector gets as input arguments
    [
      // Callback signature to create a cut-out of the state.
      (state: AppState, scope: LoadableEntityType) => IIndexedCollectionState<INumericIdentifierModel>,
      // Callback signature to return the ids to the output selector.
      (_state: AppState, _scope: LoadableEntityType, ids: number[]) => number[],
    ],
    // return type of the output selector, compatible to T
    any
  >(
    [
      /**
       * First callback - extract value(s) from `state` (state cut-out):
       * If state cut-out is unchanged, the selector returns the same object as before
       * without creating a copy of the data, to avoid re-rendering.
       *
       * @param state the current AppState
       * @param scope EntityType for what the state data should be filtered by
       * @returns a cut-out from the state
       */
      (state: AppState, scope: LoadableEntityType) => state.data[scope],
      /**
       * Callback to specify, what other input arguments should be forwarded to the output selector:
       * the ids.
       */
      (_state: AppState, _scope: LoadableEntityType, ids: number[]) => ids,
    ],
    /**
     * Output selector gets the return values of the previously defined callbacks as args (`stateCutOut, ids`).
     * Is called only, if the combination of input values have changed since the last call.
     * Otherwise the results from the prior selector call with the similar input values are returned.
     *
     * Filters the state cut-out by the given ids.
     */
    (stateCutOut, ids) => ids.map(id => stateCutOut[id])
  )

/**
 * Allows to select a single Model via its ID. Set detailsRequired to true to only
 * receive a model that was loaded with its details (meaning not as part of a collection but as
 * single GET by ID).
 * If details are required and the Object is not returned, you need to trigger a reload from the API
 */
export const selectById = <T extends INumericIdentifierModel>(
  state: AppState,
  entityType: LoadableEntityType,
  id: number,
  detailsRequired = false
): T => {
  if (!entityType || !id) {
    return null
  }

  const model = state.data[entityType][id] as T

  if (!model) {
    return null
  }

  if (detailsRequired && !model.detailResult) {
    return null
  }

  return model
}

/**
 * Generates a function that selects an entity from the store.
 *
 * @param entityType
 * @returns a function that selects an entity from the store.
 */
export const generateSelectEntityBySlugOrId = <T extends ISlugAndNumericIdentifierModel>(entityType: LoadableEntityType): (state: AppState, slugOrId: string) => T =>
  (state: AppState, slugOrId: string) =>
    selectCollection<T>(state, entityType)
      .filter((f) => f.slug === slugOrId || f.id.toString() === slugOrId)
      .shift()