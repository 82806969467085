import { Action } from "redux-saga"

import { ScopeTypes } from "@redux/common/reduxTypes"

import { IUsecaseReference } from "../types"


/*
 * Action are central elements in Redux: "An Action is a plain object that represents the intention to change the state.
 * Actions are the only way to get data into the store. Any data ... needs to be dispatched as Actions."
 *
 * This collection of Actions can be used to keep track of requests.
 *
 * NOTE Those actions stem from the first version of the Projektfabrik software and have been used widely,
 * and are still in use.
 * NOTE They should only be used for non-entity related requests.
 * NOTE To keep track of entity-related requests, use `entityRequest` actions instead.
 */

/* ****************************************************************************** */
/* Enum(erations) and types to standardize the usage of frequently used constants */
/* ****************************************************************************** */


// constants to be used to specify actions
// @todo: rework: create an enum from that?
// @todo does this need extra entries for creating valid scopetypes, insbesondere: _OPERATION?
// @todo cleanup after refactoring of the redux system
export const REQUEST_PREFIX = "REQUEST_"
export const RUNNING_SUFFIX = "_RUNNING"
export const SUCCESS_SUFFIX = "_SUCCESS"
export const LOAD_PREFIX = "LOAD_"
export const LOADING = "_LOADING"
export const COLLECTION_LOADING = "_COLLECTION_LOADING"
// export const CLEAR_LIST_PREFIX = "CLEAR_"
export const CREATE_PREFIX = "CREATE_"
export const DELETE_PREFIX = "DELETE_"
export const UPDATE_PREFIX = "UPDATE_"


// #region interfaces

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * A IUsecaseRequestRunningAction extends IUsecaseReference by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 * NOTE sending this action without an error means the request ist loading. With an error, the request is cancelled / not loading.
 */
export interface IUsecaseRequestRunningAction<ActionType extends string = string> extends Action<ActionType>, IUsecaseReference {
  error: string
}

/**
 * An IUsecaseRequestSuccessAction extends IUsecaseReference by results of a successful request,
 * and if those results should be appended to existing data.
 */
export interface IUsecaseRequestSuccessAction<ResultType, ActionType extends string = string> extends Action<ActionType>, IUsecaseReference {
  result: ResultType
  append: boolean
}

// #endregion

// #region creator functions

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/*                                                                            */
/* all created actions have a distinct type, which is a string built from     */
/* predefined prefix+suffix plus given usecaseKey                             */
/* ************************************************************************** */

/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request is running or has stopped.
 * Creating this action without an error means the request ist running/loading.
 * With an error, the request is cancelled / not loading.
 *
 * @param usecaseKey usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const usecaseRequestRunningAction =
  (usecaseKey: ScopeTypes | string, error: string = null): IUsecaseRequestRunningAction => ({
    error,
    usecaseKey,
    type: REQUEST_PREFIX + usecaseKey.toUpperCase() + RUNNING_SUFFIX,
  })

/**
 * Creates an IUsecaseRequestSuccessAction that signalises that a usecase request has succeeded.
 *
 * @param usecaseKey usecaseKey of the request
 * @param result the result value
 * @param append in case of lists? is apparently never used in reducers
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const usecaseRequestSuccessAction =
  <ResultType>(usecaseKey: string, result: ResultType, append = false): IUsecaseRequestSuccessAction<ResultType> => ({
    append,
    result,
    usecaseKey,
    type: REQUEST_PREFIX + usecaseKey.toUpperCase() + SUCCESS_SUFFIX,
  })

// #endregion