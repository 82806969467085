import { call, put, select, takeLatest } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IUser, IUserObjectRole, IUserProjectRole, TenantRole, UserRole } from "@api/schema"
import { ActionRequestType, IProjectMemberApplication, isPlatformActionRequest, RelatedEntityObjectIsNull, senderOrReceiverIsOfEntityType } from "@api/schema/action-requests"
import { INNER_TEAM_ROLES } from "@basics/pageAccess"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import {
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction
} from "@redux/common/entityRequest/actions"
import { usecaseKeyForLoadCollection } from "@redux/common/react-hooks/useEntityCollection"
import { EntityType } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { createModelSuccessAction, newLoadCollectionAction } from "@redux/common/scopedObject/actions"
import { getCurrentUser } from "@redux/saga/currentUser"
import { SubmissionError } from "@services/submissionError"
import { getUserObjectRoleByObjectIRI, hasUOROnAnyObjectRole } from "@services/userObjectRolesHelper"

import { ActionRequestsUsecase, IActionRequestReplyInput, IActionRequestReplyAction, IApplyForProjectMembershipAction, IMemberRoleInput, ProjectMemberApplicationUsecase } from "./definitions"
import { selectMyUserObjectRoles } from "../userObjectRoles/saga"

// #region saga
/**
 * Saga watcher method that is registered in redux/sagas/index rootSaga
 */
export function* actionRequestWatcherSaga(): any {
  yield takeLatest(ActionRequestsUsecase.Accept, withCallback(actionRequestSaga))
  yield takeLatest(ActionRequestsUsecase.Reject, withCallback(actionRequestSaga))
  yield takeLatest(ActionRequestsUsecase.Withdraw, withCallback(actionRequestSaga))
  yield takeLatest(ActionRequestsUsecase.Delete, withCallback(actionRequestSaga))
  yield takeLatest(ProjectMemberApplicationUsecase.Create, withCallback(createProjectMemberApplicationSaga))
}

/**
 * the saga return true if it is successful, but to mark the return value
 * this constant is used
 */
const OPERATION_SUCCESSFUL = true

/**
 * General saga, that considers all usecases.
 */
function* actionRequestSaga<ActionRequestReplyInputType extends IActionRequestReplyInput>(action: IActionRequestReplyAction<ActionRequestReplyInputType>) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type

  const currentUser: IUser = yield call(getCurrentUser)

  const userObjectRoles: IUserObjectRole[] = yield select(selectMyUserObjectRoles)
  // This is a general action request,it mean's the action request can be of any action request type.
  // Therefor the relatedEntity could be empty, b/c no relatedEntityObject
  // is defined.
  const objectRole = action.actionRequest.relatedEntity !== RelatedEntityObjectIsNull
    && getUserObjectRoleByObjectIRI(userObjectRoles, action.actionRequest.relatedEntity)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, usecaseKey))

    switch (action.type) {
      case ActionRequestsUsecase.Accept:
        // Accept is a special usecase, b/c with accepting an action request,
        // a new object could be created, which have to be integrated in the redux store.
        // E.g. by accepting a project member application an IUserProjectRole will be created.
        switch (action.actionRequest["@type"]) {
          case ActionRequestType.ProjectMemberApplication:
            const resultAccept: IUserProjectRole = yield call(
              apiClient.acceptProjectMemberApplication,
              // It is safe to cast, b/c of the check on '@type'.
              action.actionRequest as IProjectMemberApplication,
              // It is safe to cast, b/c of the check on '@type'.
              action.reply as unknown as IMemberRoleInput
            )
            yield put(createModelSuccessAction(EntityType.UserObjectRole, resultAccept))
            break
          default:
            yield call(
              apiClient.acceptActionRequest,
              action.actionRequest,
              action.reply,
              action.token
            )
            break
        }
        break
      case ActionRequestsUsecase.Reject:
        yield call(
          apiClient.rejectActionRequest,
          action.actionRequest,
          action.reply,
          action.token
        )
        break
      case ActionRequestsUsecase.Withdraw:
        yield call(
          apiClient.withdrawActionRequest,
          action.actionRequest,
          action.reply
        )
        break
      case ActionRequestsUsecase.Delete:
        if (currentUser["@id"] === action.actionRequest.sender) {
          yield call(
            apiClient.deleteActionRequestBySender,
            action.actionRequest,
          )
        } else if (objectRole.object["@id"] === action.actionRequest.receiver) {
          yield call(
            apiClient.deleteActionRequestByReceiver,
            action.actionRequest,
          )
          break
        }
    }

    // #region refresh after update
    /* General question: How to deal with reloading/refreshing of entities, especially of ActionRequests?
     * Problem: If the number of ActionRequests is very high, many ActionRequests will be loaded,
     * which could lead to long a response time for the user.
     * And most ActionRequests are loaded over a Collection-Call,b/c they provide no Single-Call.
     * Only the invitations are an exception, which provide a single-call.
     * NOTE: Should the backend offer a Single-Call for all ActionRequests?
     *
     * Currently, the ActionRequests are reloaded/refreshed via Collection-Call in the saga.
     * A different strategy would to evaluate the result of type IOperationResult and
     * depending on the result, the redux store would be updated. But that's a completely new strategy.
     * @see https://futureprojects.atlassian.net/browse/FCP-1768?focusedCommentId=15443
     * As a third possibility, especially if these Collection-Calls will offer filtering in the future, they should be probably cleared,
     * so the page itself will retrigger any loading of relevant data.
     * Or if a single call wil be available in the future, they should be directly updated.
     * @see https://futureprojects.atlassian.net/browse/FCP-1074
     */

    // Refresh/Reload the user action requests, b/c the action request was updated.
    if (senderOrReceiverIsOfEntityType(action.actionRequest, EntityType.User)) {
      yield put(newLoadCollectionAction(
        EntityType.ActionRequest,
        null,
        usecaseKeyForLoadCollection(null, null, currentUser["@id"]),
        currentUser["@id"],
        true /* loadAll */
      ))
    }

    // If the user has the right to retrieve the 'project action requests',
    // refresh/reload them, b/c the action request was updated.
    if (senderOrReceiverIsOfEntityType(action.actionRequest, EntityType.Project)
      && INNER_TEAM_ROLES.includes((objectRole as IUserProjectRole)?.["@type"])
      && action.actionRequest.relatedEntity !== RelatedEntityObjectIsNull
    ) {
      yield put(newLoadCollectionAction(
        EntityType.ActionRequest,
        null,
        usecaseKeyForLoadCollection(null, null, action.actionRequest.relatedEntity),
        action.actionRequest.relatedEntity,
        true /* loadAll */
      ))
    }

    // Refresh/Reload all platform action requests, if the current authenticated user is a platform manager or an account manager
    // and if the action request is a platform action request.
    if (isPlatformActionRequest(action.actionRequest)
      && (currentUser.roles.includes(UserRole.PlatformManager) || hasUOROnAnyObjectRole(userObjectRoles, TenantRole.Accountmanager))) {
      yield put(newLoadCollectionAction(
        EntityType.ActionRequest,
        null,
        ActionRequestsUsecase.PlatformActionRequests,
        null,
        true /* loadAll */
      ))
    }
    // #endregion

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.ActionRequest, usecaseKey, null))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess)
    }

    return OPERATION_SUCCESSFUL
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("actionRequestSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

// #endregion

// #region project member application

export function* createProjectMemberApplicationSaga(action: IApplyForProjectMembershipAction): Generator<any, IProjectMemberApplication, any> {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  try {
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, ProjectMemberApplicationUsecase.Create))

    // send request to the backend api
    const application: IProjectMemberApplication = yield call(apiClient.createProjectMemberApplication, action.project, action.motivationAndSkills)

    // signal to the use-case triggering application component: the creation was successfull
    yield put(createModelSuccessAction(EntityType.ActionRequest, application))

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.ActionRequest, ProjectMemberApplicationUsecase.Create, application))

    if (onSuccess) {
      yield call(onSuccess, application)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return application
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("createProjectMemberApplicationSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }
    // signal a INewUsecaseRequestAction
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.ActionRequest, ProjectMemberApplicationUsecase.Create, errorMessage))

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
// #endregion
