import { call, put, takeEvery } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IProject, IProposal } from "@api/schema"
import {
  IActivateProposalAction,
  ICreateProjectPdfAction,
  ICreateProposalPdfAction,
  IEmailTeamAction,
  ITransitionProposalAction,
  MyProjectsActionTypes,
} from "@redux/actions/myProjects"
import { addNotificationAction } from "@redux/actions/notifications"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import {
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction
} from "@redux/common/entityRequest/actions"
import { EntityType, ScopeTypes } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { loadModelAction } from "@redux/common/scopedObject/actions"
import { usecaseRequestRunningAction } from "@redux/common/scopedRequest/actions"
import { SubmissionError } from "@services/submissionError"


// mapping: the defined Action is transformed into a corresponding Saga-Trigger
// so when an action is dispatched, the asynchroneous saga is spawned to interact with the API
// every single Saga is defined below
export function* myProjectsWatcherSaga(): any {
  yield takeEvery(MyProjectsActionTypes.CreateProjectPdf, withCallback(createProjectPdfSaga))
  yield takeEvery(MyProjectsActionTypes.CreateProposalPdf, withCallback(createProposalPdfSaga))
  yield takeEvery(MyProjectsActionTypes.EmailTeam, withCallback(emailTeamSaga))

  yield takeEvery(MyProjectsActionTypes.ActivateProposal, withCallback(activateProposalSaga))
  yield takeEvery(MyProjectsActionTypes.TransitionProposal, withCallback(transitionProposalSaga))
}

/**
 * saga to trigger the creation of a project-pdf
 */
function* createProjectPdfSaga(action: ICreateProjectPdfAction) {
  try {
    yield call(apiClient.createProjectPdf, action.project)
    yield put(addNotificationAction("message.project.pdfCreationTriggered", "success"))
  } catch (err) {
    if (err instanceof Error) {
      yield put(addNotificationAction(err.message, "error"))
    } else {
      // @todo
    }
  }
}

/**
 * saga to trigger the creation of an proposal-pdf
 */
function* createProposalPdfSaga(action: ICreateProposalPdfAction) {
  try {
    yield call(apiClient.createProposalPdf, action.proposal)
    yield put(addNotificationAction("message.project.pdfCreationTriggered", "success"))
  } catch (err) {
    if (err instanceof Error) {
      yield put(addNotificationAction(err.message, "error"))
      const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
      showErrorsInTestEnvironment("createProposalPdfSaga", errorMessage, action, err)
    } else {
      // @todo
    }
  }
}

/**
 * saga to trigger a team-email
 */
function* emailTeamSaga(action: IEmailTeamAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  const usecaseKey = action.type // @TODO fixme: usecaseKey may be refactored into action, see loadModelAction etc

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey))
    yield call(apiClient.emailProjectMembers, action.project, action.email)

    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Project, usecaseKey, null))

    if (onSuccess) {
      yield call(onSuccess, action.project)
    }

    return true
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("emailTeamSaga", errorMessage, action, err)
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Project, usecaseKey, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}


/**
 * saga to activate a non-active project proposal
 */
function* activateProposalSaga(action: IActivateProposalAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation))
    const proposal: IProposal = yield call(apiClient.activateProposal, action.proposal)

    // reload the project to have a consistent model in the store, instead of integrating the
    // proposal manually, this also sets the request scope isLoading to false
    yield putWait(loadModelAction(EntityType.Project, (proposal.project as IProject).id))

    if (onSuccess) {
      yield call(onSuccess, proposal)
    }

    return proposal
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("activateProposalSaga", errorMessage, action, err)
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

/**
 * saga to transition a project proposal, e.g. submitting it
 */
function* transitionProposalSaga(action: ITransitionProposalAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation))

    const proposal: IProposal = yield call(apiClient.transitionProposal, action.proposal, action.transition)

    // reload the project to have a consistent model in the store, instead of integrating the
    // proposal manually, this also sets the request scope isLoading to false
    yield putWait(loadModelAction(EntityType.Project, (proposal.project as IProject).id))

    if (onSuccess) {
      yield call(onSuccess, proposal)
    }

    return proposal
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: err.message })
      }
    }

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("transitionProposalSaga", errorMessage, action, err)
    yield put(usecaseRequestRunningAction(ScopeTypes.ProposalOperation, errorMessage))
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
