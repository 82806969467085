import { combineReducers } from "redux"

import { IRequestState, initialRequestState } from "@modules/frontend-definitions/src"
import { ScopeTypes } from "@redux/common/reduxTypes"

import {
  REQUEST_PREFIX,
  RUNNING_SUFFIX,
  SUCCESS_SUFFIX,
  IUsecaseRequestRunningAction,
  IUsecaseRequestSuccessAction
} from "./actions"

/*
 * defines a RequestReducer (for states of the request-process)
 */

/**
 * All possible ActionTypes for a running request for all ScopeTypes
 */
export type ScopeRequestTypeRunning = `${typeof REQUEST_PREFIX}${Uppercase<ScopeTypes>}${typeof RUNNING_SUFFIX}`

/**
 * All possible ActionTypes for a success request for all ScopeTypes
 */
export type ScopeRequestTypeSuccess = `${typeof REQUEST_PREFIX}${Uppercase<ScopeTypes>}${typeof SUCCESS_SUFFIX}`

/**
 * IRequestActions may be of type IUsecaseRequestRunningAction (while requesting) or IUsecaseRequestSuccessAction (after successful request)
 */
export type IRequestActions =
  IUsecaseRequestRunningAction<ScopeRequestTypeRunning | ScopeRequestTypeSuccess>
  | IUsecaseRequestSuccessAction<any, ScopeRequestTypeRunning | ScopeRequestTypeSuccess>

/**
 * This Reducer is a general reducer for all kinds of requests to the API. It is used in src/redux/reducer/requests.ts
 * to combine a set of scopeRequestReducer for all possible Entitiy-Types and their operations with the API.
 *
 * It generates a request-state for every possible request. Before accessing requested data, the request-state delivers
 * information, if the request is still in progress or finished, and if it was finished successful or not.
 *
 * @param scope The operation for which the reducer creates an IRequestState
 * @returns A reducer function that is specialized to the given ScopeType and reduces IRequestActions on an IRequestState
 */
export const scopedRequestReducer = (scope: ScopeTypes): (state: IRequestState, action: IRequestActions) => IRequestState => {
  const requestReducer =
    (state: IRequestState = initialRequestState, action: IRequestActions) => {
      switch (action.type) {
        case REQUEST_PREFIX + scope.toUpperCase() + RUNNING_SUFFIX:
          return {
            // take the current state and manipulate isLoading and loadingError
            ...state,
            isLoading: !(action as IUsecaseRequestRunningAction).error,
            loadingError: (action as IUsecaseRequestRunningAction).error,
            loaded: false,
          }

        case REQUEST_PREFIX + scope.toUpperCase() + SUCCESS_SUFFIX:
          return {
            // take the current state and manipulate isLoading: false (no longer loading) and loadingError: null (no errors)
            ...state,
            isLoading: false,
            loadingError: null,
            loaded: true,
          }

        default:
          return state
      }
    }

  return requestReducer
}



/**
 * For every request-Operation (loading or other requests to the API) is a requestReducer necessary to have access
 * to the state of the single request - e.g. to test, if a loading is running and the loaded data may be accessed.
 *
 * Instead of creating a requestReducer for each single use case
 * (like confirm_validation, user_registration, user_management, ... / ScopeTypes.UserForManager, ...)
 * we create default request reducers/state here: up to three for each entity type including
 * one for fetching a single entity, one for fetching a collection and one for an create/update/delete
 * operation.
 * This should be sufficent also for complex cases, e.g. that need to load a single project,
 * a list of challenges and create a proposal
 *
 * @todo but it may not be enough to load two (or more) different use-cases on the same EntityType, e.g.
 * loading FeedbackInvitations FOR and FROM the processmanager on the manager-dashboard
 */
export const scopedRequestCombinedReducer = combineReducers({
  // attachmentDefinitionOperation: scopedRequestReducer(ScopeTypes.AttachmentDefinitionOperation),

  // discussionLoading: scopedRequestReducer(ScopeTypes.DiscussionLoading),
  // discussionCollectionLoading: scopedRequestReducer(ScopeTypes.DiscussionCollectionLoading),
  // discussionOperation: scopedRequestReducer(ScopeTypes.DiscussionOperation),

  // challengeLoading: scopedRequestReducer(ScopeTypes.ChallengeLoading),
  // challengeCollectionLoading: scopedRequestReducer(ScopeTypes.ChallengeCollectionLoading),
  // challengeOperation: scopedRequestReducer(ScopeTypes.ChallengeOperation),

  // challengeConcretizationOperation: scopedRequestReducer(ScopeTypes.ChallengeConcretizationOperation),

  // feedbackInvitationLoading: scopedRequestReducer(ScopeTypes.FeedbackInvitationLoading),
  // feedbackInvitationCollectionLoading: scopedRequestReducer(ScopeTypes.FeedbackInvitationCollectionLoading),
  // feedbackInvitationOperation: scopedRequestReducer(ScopeTypes.FeedbackInvitationOperation),

  // feedbackPostLoading: scopedRequestReducer(ScopeTypes.FeedbackPostLoading),
  // feedbackPostCollectionLoading: scopedRequestReducer(ScopeTypes.FeedbackPostCollectionLoading),
  // feedbackPostOperation: scopedRequestReducer(ScopeTypes.FeedbackPostOperation),

  // processLoading: scopedRequestReducer(ScopeTypes.ProcessLoading),
  // processesLoading: scopedRequestReducer(ScopeTypes.ProcessCollectionLoading),
  // processOperation: scopedRequestReducer(ScopeTypes.ProcessOperation),

  // projectLoading: scopedRequestReducer(ScopeTypes.ProjectLoading),
  // projectsLoading: scopedRequestReducer(ScopeTypes.ProjectCollectionLoading),
  // projectOperation: scopedRequestReducer(ScopeTypes.ProjectOperation),

  // projectMembershipOperation: scopedRequestReducer(ScopeTypes.ProjectMembershipOperation),

  // proposalAttachmentOperation: scopedRequestReducer(ScopeTypes.ProposalAttachmentOperation),

  // proposalLoading: scopedRequestReducer(ScopeTypes.ProposalLoading),
  // proposalsLoading: scopedRequestReducer(ScopeTypes.ProposalManagementCollectionLoading),
  // proposalOperation: scopedRequestReducer(ScopeTypes.ProposalOperation),

  // teamUploadOperation: scopedRequestReducer(ScopeTypes.TeamUploadOperation),

  // userLoading: scopedRequestReducer(ScopeTypes.UserLoading),
  // usersLoading: scopedRequestReducer(ScopeTypes.UserCollectionLoading),
  // userOperation: scopedRequestReducer(ScopeTypes.UserOperation),

  verificationOperation: scopedRequestReducer(ScopeTypes.VerificationOperation),

  // #region specific request reducers for module OIDC
  oidcLoadProvidersRequest: scopedRequestReducer(ScopeTypes.OIDCLoadProviders),
  oidcFetchTokenRequest: scopedRequestReducer(ScopeTypes.OIDCFetchToken),
  oidcLoginWithTokenRequest: scopedRequestReducer(ScopeTypes.OIDCLoginWithToken),
  // #endregion
})