import { IProject, IProjectCreation, IProjectMembership, IProjectFollowership } from "@api/schema"
import { EntityType } from "@redux/common/reduxTypes"

/* This enum defines the usecases around the "onboarding".
 * "Onboarding" is everything related to elements that the user creates before login/registration,
 * to persist those elements after a successful login/registration.
 */
export enum OnboardingUsecases {
  /**
   * add "new idea" data to the onboarding state
   */
  AddNewIdea = "_usecase_add_onboarding_new_idea",
  /**
   * add "new project from idea" data to the onboarding state
   */
  AddNewProjectFromIdea = "_usecase_add_onboarding_new_project_from_idea",
  /**
   * add "new project member application" data to the onboarding state
   */
  AddNewProjectMemberApplication = "_usecase_add_onboarding_new_project_member_application",
  /**
   * add "new project followership" data to the onboarding state
   */
  AddNewProjectFollowership = "_usecase_add_onboarding_new_project_followership",

  /**
   * reset the state after the onboarding data has been processed
   */
  ResetOnboardingData = "_usecase_reset_onboarding_data",
}

/**
 * types of existing onboarding elements
 */
export enum OnboardingType {
  None = "none",

  // NOTE: those strings are used within the translation files, so do not change them without changing there too
  NewIdea = "newIdea",
  NewProjectFromIdea = "newProjectFromIdea",
  NewProjectMemberApplication = "newProjectMemberApplication",
  NewProjectFollowership = "newProjectFollowership",
}

/**
 * existing/allowed Types of IModel, for which an Onboarding process exists
 * export for tests
 *
 * @todo multi refactor Onboarding, considering following ticket:
 * @see https://futureprojects.atlassian.net/browse/FCP-1616
 */
export type OnboardingModelTypes = IProject | IProjectCreation | IProjectMembership | IProjectFollowership

export const getEntityTypeFromOnboardingType = (onboardingType: OnboardingType): EntityType => {

  switch (onboardingType) {

    case OnboardingType.NewIdea:
    case OnboardingType.NewProjectFromIdea:
      return EntityType.Project

    case OnboardingType.NewProjectMemberApplication:
      return EntityType.ProjectMembership

    case OnboardingType.NewProjectFollowership:
      return EntityType.ProjectFollowership

    case OnboardingType.None:
      return null
  }
}