/* eslint-disable */
const merger = require("json-object-merge")

const SUPPORTED_LOCALES = require("./locales/locales-config").SUPPORTED_LOCALES
const DEFAULT_LOCALE = require("./locales/locales-config").DEFAULT_LOCALE

const formatDate = require("./src/services/formatDate").formatDate
const dateFormats = require("./src/services/formatDate").formats
const dateFormatNames = Object.keys(dateFormats)


/**
 * This is the configuration of the internationalisation module I18n.
 * 
 * IMPORTANT NOTE FOR WORKING WITH THIS FILE
 * 
 * Changes in this file will become effectiv in debug mode,
 * nevertheless some framework code continues using the old (cached) version of this file.
 * 
 * To prevent this, always rebuild the container after changes in this file.
 * 
 * NOTE in Tests: keep note of the i18nMock.ts!
 */
module.exports = {
  locales: SUPPORTED_LOCALES, // may be accessed via useRouter().locales, @see: LanguageSelector.tsx
  defaultLocale: DEFAULT_LOCALE,

  logger: () => null,
  interpolation: {
    format: (value, format, locale) => {
      if (value === null || value === undefined) {
        return String(value)
      }

      // @todo currency symbol configurable
      if (format === "currency") {
        return new Intl.NumberFormat(locale, { style: "currency", currency: "EUR" }).format(value)
      }

      if (format === "number") {
        return new Intl.NumberFormat(locale).format(value)
      }

      if (dateFormatNames.includes(format)) {
        return formatDate(locale, value, format)
      }

      return value
    }
  },
  /*
   * List of pages or regex filters, each with a list of auto-loaded namespace files (not shortcuts!).
   */
  pages: {
    "rgx:^.*": [
      // `common` contains a lot of page-specific stuff and is still a residue.
      // It should not be used after full migration to dynamicNamespaces.
      "common",
      // `default` contains small content used by multiple pages
      "default",
      // `error` contains a lot of page-specific error messages and is still a residue.
      // It should not be used after full migration to dynamicNamespaces.
      "error"
    ],
    // @todo migrate management content usage to dynamicNamespaces and remove entry afterwards
    "rgx:^/management": [
      "management"
    ],
    // @todo migrate pubtools content usage to dynamicNamespaces and remove entry afterwards
    "rgx:^/netiquette": [
      "static/netiquette"
    ],
    // @todo migrate pubtools content usage to dynamicNamespaces and remove entry afterwards
    "rgx:^/tools/pubtools": [
      "pubtools"
    ],
    // @todo migrate terms-of-use content usage to dynamicNamespaces and remove entry afterwards
    "rgx:^/terms-of-use": [
      "terms-of-use"
    ]
  },
  /**
  * @type {LocaleLoader}
  */
  loadLocaleFrom: (lang, ns) =>
    // You should return a Promise with the JSON file.
    Promise.all([
      require(`./locales/${lang}/${ns}.json`),
      require(`./locales/custom-locales/${lang}/${ns}.json`)
    ]).then(
      (n) => merger.default(n[0], n[1]),
    ).catch(e => console.log("Error: ", e))
}
