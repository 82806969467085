
import { captureException } from "@sentry/nextjs"

import { HydraClient } from "@api/hydraClient"
import {
  ChallengeTransitionState,
  // IAttachmentDefinition,
  IAuthReply,
  IChallenge,
  // IChallengeConcretization,
  IContactEmail,
  ICredentials,
  IEmailChange,
  IFeedbackInvitation,
  IFeedbackPost,
  IFundApplication,
  IHydraCollection,
  IModel,
  IMotivationAndSkills,
  INativeHydraCollection,
  INewPasswordRequest,
  INumericIdentifierModel,
  IOperationResultOutput,
  IPasswordChange,
  IPasswordReset,
  IPasswordResetRequest,
  IProgram,
  IProject,
  IProjectCreation,
  IProposal,
  IProposalAttachment,
  IRI,
  IRIstub,
  ISysinfo,
  ITeamEmail,
  ITeamUpload,
  IUser,
  IUserEmail,
  IUserObjectRole,
  IUserProjectRole,
  IVerification,
  MembershipRole,
  ProjectState,
  ProposalTransitionState,
  ReportableModel,
  SubResourceUrl,
  SubResourceUrlPattern
} from "@api/schema"
import { idFromIModelOrIRI } from "@basics/util-importless"
import { IChapter, IDocument } from "@modules/documents/src/models/documents"
import { IOIDCAPI } from "@modules/oidc/src"
import { IOIDCProvider } from "@modules/oidc/src/models/IOIDCProvider"
import { CreateableEntityType, DeleteableEntityType, EntityType, LoadableEntityType, StatisticsType, UpdateableEntityType, UploadType } from "@redux/common/reduxTypes"
import { UpdateMembershipRole, IActionRequestReplyInput, IMemberRoleInput } from "@redux/usecases/actionRequests/definitions"
import { ILockUnlockEntityApi } from "@redux/usecases/lock/definitions"
import { ILockableModel } from "@redux/usecases/lock/schema-definitions"
import { IReportEntityApi } from "@redux/usecases/report/definitions"
import { IAbuseReportDTO } from "@redux/usecases/report/schema-definitions"
import { FCP_API_ENTRYPOINT } from "config"

import { entityEndpointList, uploadEndpointList, statisticsEndpointList, entityTypeFromIModelOrIRI, iriFromIModelOrIRI } from "./entityTypeEndpointDefinitions"
import { IProjectMemberApplication, IActionRequest } from "./schema/action-requests"
import { IStatistics } from "./schema/statistics"
import { IStateChangeDTO, IUserWriteDTO, TransitionDTO } from "./schema-dto"


// #region types and interface definitions

// könnte helfen, auf anwendungscoder-ebene Dinge leichter zu verstehen, aber nötig ist es nicht
// könnte frühere Prüfung erlauben, ob ein sub-endpunkt überhaupt existiert
// enum ApiSubCollectionUsecases {
//   ChallengesOfProgram,
//   ChallengesOfProvider,
//   CategoriesOfProgram,
//   IdeasOfProgram,
//   IdeasOfUser,
//   ProgramsOfProvider,
//   RelatedProgramsOfChallenges,
//   ProjectsOfProgram,
//   ProjectsOfUser,
//   RolesOfUser, // "my UserObjectRoles"
//   MembershipsOfProject,
//   ManagersOfTenant,
//   ManagersOfProgram,
//   ManagersOfChallenge,
// }

// type ApiUsecaseEndpointUrl = `/${string}/[id]/${string}`

// /**
//  * this data defines an endpoint
//  */
// interface ApiUsecaseEndpoint {
//   /**
//    * url must not end with a slash
//    */
//   url: ApiUsecaseEndpointUrl
//   /** entity type that is returned from that endpoint */
//   entityType: EntityType
//   specialHandling?: string // @todo: Anweisungen darüber, wie der Reducer diesen Typ behandeln soll, könnte aber auch schlicht im Reducer definiert werden!
// }

// /**
//  * an endpoint for a specific entity is defined as
//  * EntityType => Endpoint
//  */
// export type ApiUsecaseEndpointList = {
//   [key in ApiSubCollectionUsecases]: ApiUsecaseEndpoint
// }

// /**
//  * list of all api usecase endpoints that deliver collections of entities that are connected to the "parent" entity
//  */
// export const apiUsecaseEndpointList: ApiUsecaseEndpointList = {
//   [ApiSubCollectionUsecases.CategoriesOfProgram]: {
//     url: "/programs/[id]/categories",
//     entityType: EntityType.Category
//   },
//   [ApiSubCollectionUsecases.ChallengesOfProgram]: {
//     url: "/programs/[id]/related_challenges",
//     entityType: EntityType.Challenge
//   },
//   [ApiSubCollectionUsecases.ChallengesOfProvider]: {
//     url: "/providers/[id]/challenges",
//     entityType: EntityType.Challenge
//   },
//   [ApiSubCollectionUsecases.IdeasOfProgram]: {
//     url: "/programs/[id]/ideas",
//     entityType: EntityType.Project // @todo: EntityType.Ideas!!
//   },
//   [ApiSubCollectionUsecases.IdeasOfUser]: {
//     url: "/users/[id]/ideas",
//     entityType: EntityType.Project // @todo: EntityType.Ideas!!
//   },
//   [ApiSubCollectionUsecases.ManagersOfChallenge]: {
//     url: "/challenges/[id]/managers",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.ManagersOfProgram]: {
//     url: "/programs/[id]/managers",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.ManagersOfTenant]: {
//     url: "/tenants/[id]/managers",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.MembershipsOfProject]: {
//     url: "/projects/[id]/memberships",
//     entityType: EntityType.UserObjectRole
//   },
//   [ApiSubCollectionUsecases.ProgramsOfProvider]: {
//     url: "/providers/[id]/programs",
//     entityType: EntityType.Program
//   },
//   [ApiSubCollectionUsecases.ProjectsOfProgram]: {
//     url: "/programs/[id]/projects",
//     entityType: EntityType.Project
//   },
//   [ApiSubCollectionUsecases.ProjectsOfUser]: {
//     url: "/users/[id]/projects",
//     entityType: EntityType.Project
//   },
//   [ApiSubCollectionUsecases.RelatedProgramsOfChallenges]: {
//     url: "/challenges/[id]/related_programs",
//     entityType: EntityType.Program
//   },
//   [ApiSubCollectionUsecases.RolesOfUser]: {
//     url: "/users/[id]/object_roles",
//     entityType: EntityType.UserObjectRole
//   },
// }

// #endregion


/**
 * replaces the [id] placeholder element within an IRIstub by the given id
 *
 * @param url an IRIstub/endpoint URL
 * @param id id to be inserted instead the [id] placeholder
 * @returns the IRIstub with the concrete id instead of the placeholder
 */
export const replaceIdPlaceholder = (url: IRIstub, id: number | string): IRIstub =>
  url?.replace("[id]", id?.toString()) as IRIstub

/**
 * @param url an IRIstub/endpoint URL
 * @returns true, if the given url includes an [id] placeholder
 */
export const hasIdPlaceholder = (url: IRIstub): boolean =>
  url && url.indexOf("[id]") >= 0

/**
 * This class provides functions for the communication with the backend-API
 * based on HydraClient, that uses Axios for fetching and sending data to servers.
 * All relevant endpoints are addressed here.
 *
 * It implements API interfaces from different modules.
 */
export class ProjektfabrikClient extends HydraClient implements
  IOIDCAPI<IAuthReply, INativeHydraCollection<IOIDCProvider>>,
  ILockUnlockEntityApi,
  IReportEntityApi {

  // #region generalEntityFunctions

  /**
   * General function to load one Entity from the backend api by id.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * @param entityType entitytype that should be loaded
   * @param slugOrId id or slug of the entity that should be loaded
   * @returns the entity
   */
  public loadSingleEntity = <T extends INumericIdentifierModel>(entityType: LoadableEntityType, slugOrId: number | string): Promise<T> => {
    // get the endpoint definition from the entityEndpointList
    const endpoint = entityEndpointList[entityType]

    // start the request and return results
    return this.get(endpoint.url + "/" + slugOrId.toString())
  }

  /**
   * General function to load the first page of a collection of entities from the backend api.
   * Entity-specific endpoint must be defined in the entityEndpointList.
   *
   * The result returns the next page url in the IHydraCollection["view"]["next"]
   * This url should be passed to the get-function to fetch its content.
   *
   * @todo: müßte diese Funktion korrekterweise heißen loadEntityCollectionFirstPage? Der Abruf von Folge-Pages erfolgt direkt über die .get-Funktion
   *
   * @param entityType entitytype that should be loaded
   * @param query list of criteria for the entities that should be loaded
   * @param parentIri IRI of the parent entity in case of loading the collection from a subresource of the parent's endpoint
   * @returns a collection of entities
   */
  public loadEntityCollection = <T extends INumericIdentifierModel>(entityType: LoadableEntityType, query: Record<string, unknown>, parentIri: IRI): Promise<IHydraCollection<T>> => {
    // get the endpoint definition from the entityEndpointList
    const endpointUrl = ProjektfabrikClient.getEndpointUrl(entityType, parentIri)

    // start the request and return results
    return this.get(endpointUrl, query)
  }

  /**
   * General function to create an entity of a given type via the backend api.
   *
   * @param entityType has to be a CreateableEntityType
   * @param entity the entity to be created
   * @returns the created entity
   */
  public createEntity = <T extends INumericIdentifierModel>(entityType: CreateableEntityType, entity: INumericIdentifierModel, parentIri?: IRI): Promise<T> => {
    // special use cases
    switch (entityType) {
      // ProposalAttachment is a file and properties, so it has to be handled as upload
      case EntityType.ProposalAttachment:
        return this.createProposalAttachment(entity) as Promise<T>
      // TeamUpload is a file and properties, so it has to be handled as upload
      case EntityType.TeamUpload:
        return this.createTeamUpload(entity, parentIri) as Promise<T>
      case EntityType.FeedbackPost:
        // replying to an existing FeedbackPost by calling a special endpoint
        return this.replyFeedbackPost(entity, parentIri) as Promise<T>
    }

    const endpointUrl = ProjektfabrikClient.getEndpointUrl(entityType, parentIri)

    // start the request
    return this.post(endpointUrl, entity)
  }


  /**
   * General function to update a given entity via the backend api.
   * Entity-specific endpoint is encoded in the IRI/@id of every entity
   *
   * @param entity the entity to be updated
   * @returns the updated entity
   */
  public updateEntity = <T extends INumericIdentifierModel>(entity: INumericIdentifierModel): Promise<T> => {
    // special use cases
    switch (entityTypeFromIModelOrIRI<UpdateableEntityType>(entity)) {
      // ProposalAttachment is a file and properties, so it has to be handled as upload
      case EntityType.ProposalAttachment:
        return this.updateProposalAttachment(entity) as Promise<T>
      // TeamUpload is a file and properties, so it has to be handled as upload
      case EntityType.TeamUpload:
        return this.updateTeamUpload(entity) as Promise<T>
      case EntityType.Project:
        // don't try to set the state to "inactive" (even when it is already inactive),
        // as this is forbidden by the API and would cause a validation error (for PM, admin, coordinator)
        if ((entity as IProject).state && (entity as IProject).state === ProjectState.Inactive) {
          delete (entity as IProject).state
        }
      // no break
    }

    // start the request: endpoint is encoded in the IRI/@id of every entity
    return this.patch(entity["@id"], entity)
  }


  /**
   * General function to delete a given entity via the backend api.
   * Entity-specific endpoint is encoded in the IRI/@id of every entity
   *
   * @param entity the entity to be deleted
   * @returns nothing after deleting the entity
   */
  public deleteEntity = (entity: INumericIdentifierModel): Promise<void> => {
    // special use cases
    switch (entityTypeFromIModelOrIRI<DeleteableEntityType>(entity)) {
      // FeedbackPosts are not deleted, but its content to keep the discussion chain
      // therefor a special endpoint is used
      case EntityType.FeedbackPost:
        return this.deleteFeedbackPostContent(entity as IFeedbackPost)
    }

    // start the request: endpoint is encoded in the IRI/@id of every entity
    return this.delete(entity["@id"])
  }

  /**
   * General function to upload a file corresponding to an entity to a given endpoint
   *
   * @param uploadType the type of upload that should be performed
   * @param entity the entity to which the upload is connected
   * @param file the file that should be uploaded
   * @returns a Promise of the (changed) entity
   */
  public uploadFile = <T extends INumericIdentifierModel>(uploadType: UploadType, entity: INumericIdentifierModel, file: File): Promise<T> => {
    const formData = new FormData()
    formData.append("file", file)
    // use the uploadType-corresponding endpoint url and replace the placeholder [id] by the actual .id of the entity
    const url = replaceIdPlaceholder(uploadEndpointList[uploadType]?.url, entity?.id)
    return this.upload(url, file ? formData : null)
  }

  // #endregion

  // #region lock trait

  /**
   * Locks a lockable entity by addressing its /lock endpoint.
   *
   * @param lockableEntity an entity that is lockable/unlockable
   * @param stateChangeDto message + internalNote to comment the state change
   * @returns a standard operation result output
   */
  public lockEntity = (lockableEntity: ILockableModel, stateChangeDto: IStateChangeDTO): Promise<IOperationResultOutput> => {
    return this.post(lockableEntity["@id"] + "/lock", stateChangeDto)
  }

  /**
   * Unlocks a lockable entity by addressing its /unlock endpoint.
   *
   * @param lockableEntity an entity that is lockable/unlockable
   * @param stateChangeDto message + internalNote to comment the state change
   * @returns a standard operation result output
   */
  public unlockEntity = (lockableEntity: ILockableModel, stateChangeDto: IStateChangeDTO): Promise<IOperationResultOutput> => {
    return this.post(lockableEntity["@id"] + "/unlock", stateChangeDto)
  }

  // #endregion

  // #region report entity trait

  /**
   * Reports a reportable entity by addressing its /report endpoint.
   *
   * @param reportableEntity an entity that is reportable
   * @param abuseReportDto message why the entity is reported for misuse
   * @returns a standard operation result output
   */
  public reportEntity = (reportableEntity: ReportableModel, abuseReportDto: IAbuseReportDTO): Promise<IOperationResultOutput> => {
    return this.post(reportableEntity["@id"] + "/report", abuseReportDto)
  }
  // #endregion

  // #region general statistics function

  /**
   * General function to load a global or a single statistic.
   * statistics-specific endpoint must be defined in the statisticsEndpointList.
   *
   * @todo multi split into 2 methods, global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
   *
   * @param statisticsType type of statistics that should be loaded
   * @returns the statistics
   */
  public loadStatistics = <T extends IStatistics>(statisticsType: StatisticsType, id?: number): Promise<T> => {
    // get the endpoint definition from the statisticsEndpointList
    let endpointUrl = statisticsEndpointList[statisticsType]
    if (id) {
      endpointUrl = statisticsEndpointList[statisticsType].replace(
        "[id]", id.toString()
      ) as SubResourceUrlPattern
    }

    // start the request and return results
    return this.get(endpointUrl)
  }

  /**
   * Getting the endpoint url the entityType. If a parentIri is given, returns the SubResourceUrl
   *
   * @throws an Error, if the SubEndPoint could not be found with the given parameters.
   * @param entityType
   * @param parentIri
   * @returns SubRessourceUrl
   */
  public static getEndpointUrl = (entityType: EntityType, parentIri?: IRI): IRIstub | SubResourceUrl => {
    let endpointUrl = entityEndpointList[entityType].url
    if (parentIri) {
      const parentType = entityTypeFromIModelOrIRI(parentIri)
      const subResource = entityEndpointList[parentType].subResource
      const subEndpointName = subResource?.[entityType]?.url

      try {
        if (!subEndpointName) {
          throw new Error("failure.nonExistingSubEndpoint", { cause: entityType + " @ " + parentIri })
        }

        endpointUrl = subEndpointName.replace("[id]", idFromIModelOrIRI(parentIri).toString()) as SubResourceUrl
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("WARNING: sub endpoint could not be found")
        captureException(err, { extra: { entityType, parentIri } })
      }
    }

    return endpointUrl
  }

  // #endregion


  /* *****************************************************************
   * specific functions that can not be handled by general functions
   *******************************************************************/


  // #region ProposalAttachment

  /**
   * An ProposalAttachment consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an IProposalAttachment to be sent to the API
   * @returns the API reply as IProposalAttachment
   */
  public createProposalAttachment = (entity: IProposalAttachment): Promise<IProposalAttachment> => {
    const formData = new FormData()

    if (entity.proposal) {
      formData.append("proposal", iriFromIModelOrIRI(entity.proposal))
    }
    if (entity.definition) {
      formData.append("definition", iriFromIModelOrIRI(entity.definition))
    }
    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }

    return this.upload(entityEndpointList[EntityType.ProposalAttachment].url, formData)
  }

  /**
   * An ProposalAttachment consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an IProposalAttachment to be updated
   * @returns the API reply as IProposalAttachment
   */
  public updateProposalAttachment = (entity: IProposalAttachment): Promise<IProposalAttachment> => {
    const formData = new FormData()

    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }

    return this.upload(entity["@id"], formData, { method: "PATCH" })
  }

  // #endregion

  // #region TeamUpload

  /**
   * An TeamUpload consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an ITeamUpload to be created
   * @returns the API reply as ITeamUpload
   */
  public createTeamUpload = (entity: ITeamUpload, projectIri: IRI): Promise<ITeamUpload> => {
    const formData = new FormData()

    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }
    if (typeof entity.category === "string") {
      formData.append("category", entity.category)
    }
    if (typeof entity.content === "string") {
      formData.append("content", entity.content)
    }
    return this.upload(ProjektfabrikClient.getEndpointUrl(EntityType.TeamUpload, projectIri), formData)
  }

  /**
   * An TeamUpload consists of usual properties as well as a File.
   * Therefor it must be handled differently.
   *
   * @param entity an ITeamUpload to be updated
   * @returns the API reply as ITeamUpload
   */
  public updateTeamUpload = (entity: ITeamUpload): Promise<ITeamUpload> => {
    const formData = new FormData()
    if (entity.file instanceof File) {
      formData.append("file", entity.file)
    }
    if (typeof entity.category === "string") {
      formData.append("category", entity.category)
    }
    if (typeof entity.content === "string") {
      formData.append("content", entity.content)
    }
    return this.upload(entity["@id"], formData, { method: "PATCH" })
  }

  // #endregion


  // #region Auth
  public requestAuthToken = (credentials: ICredentials): Promise<IAuthReply> => {
    return this.post("/authentication_token", credentials)
  }

  public refreshAuthToken = (refreshToken: string): Promise<IAuthReply> => {
    return this.post("/refresh_token", { refresh_token: refreshToken })
  }
  // #endregion

  // #region FeedbackInvitation

  public activateFeedbackInvitation = (fbi: IFeedbackInvitation): Promise<void> => {
    return this.post(`/feedback_invitations/${fbi.id}/activate`, fbi)
  }
  // #endregion



  // #region FeedbackPost

  public deleteFeedbackPostContent = (post: IFeedbackPost): Promise<void> => {
    // not the feedbackpost is deleted, but its content
    return this.delete(post["@id"] + "/delete-content")
  }

  /**
   * Sents new post as a reply to an existing post
   *
   * @param post The new post.
   * @param parentIri The IRI of the already existing post
   * @returns the new post, updated
   */
  public replyFeedbackPost = (post: IFeedbackPost, parentIri: IRI): Promise<IFeedbackPost> => {
    return this.post(parentIri + "/reply", post)
  }

  // #endregion


  /* #region challenge */
  public transitionChallenge = (challenge: IChallenge, transition: ChallengeTransitionState): Promise<IChallenge> => {
    return this.post(challenge["@id"] + "/transition", {}, { params: { action: transition } })
  }

  /* #endregion */


  /* #region Proposals */
  public activateProposal = (proposal: IProposal | IFundApplication): Promise<void> => {
    return this.post(proposal["@id"] + "/activate", {})
  }

  public createProposalPdf = (proposal: IProposal | IFundApplication): Promise<void> => {
    return this.post(proposal["@id"] + "/create-pdf", [])
  }

  public transitionProposal = (proposal: IProposal | IFundApplication, transition: ProposalTransitionState): Promise<IChallenge> => {
    return this.post(proposal["@id"] + "/transition", {}, { params: { action: transition } })
  }

  /* #endregion */


  /* #region Program */
  // @todo update when multi-processes are implemented
  public getCurrentProgram = async (): Promise<IProgram> => {
    return this.loadEntityCollection<IProgram>(EntityType.Program, null, null)
      .then((programs) => programs.member.shift())
      // we cannot simply return the first process from the collection but need to fetch him separately
      // as his challenges are only returned in the detailResult.
      // We can also not assume that the ID is always 1...
      .then(program => program ? this.loadSingleEntity(EntityType.Program, program.id) : null)
  }

  // @todo multi adapt to program => currently not fully integrated in multitenant-backend
  // https://futureprojects.atlassian.net/browse/FCP-1430
  public sendEmailToUsers = (programId: number, userMessage: IUserEmail): Promise<void> => {
    return this.post(`/programs/${programId}/contact-users`, userMessage)
  }
  // @todo multi adapt to program => currently not integrated in multitenant-backend
  // https://futureprojects.atlassian.net/browse/FCP-1430
  public sendContactEmail = (programId: number, email: IContactEmail): Promise<void> => {
    return this.post(`/programs/${programId}/contact`, email)
  }
  // #endregion

  // #region Project
  public classifyProject = (project: IProject): Promise<IProject> => {
    return this.post(project["@id"] + "/classify", { programClassificationCode: project.programClassificationCode })
  }

  public activateProject = (project: IProject): Promise<IProject> => {
    return this.post(project["@id"] + "/activate", {})
  }

  public deactivateProject = (project: IProject): Promise<IProject> => {
    return this.post(project["@id"] + "/deactivate", {})
  }

  public createProject = (project: IProjectCreation | IProject): Promise<IProject> => {
    return this.post("/projects", project)
  }

  public createProjectPdf = (project: IProject): Promise<void> => {
    return this.post(project["@id"] + "/create-pdf", [])
  }

  public emailProjectMembers = (project: IProject, email: ITeamEmail): Promise<void> => {
    return this.post(project["@id"] + "/email-team", email)
  }

  public createProjectMemberApplication = (project: IProject, memberApplicationData: IMotivationAndSkills): Promise<IProjectMemberApplication> => {
    return this.post(project["@id"] + "/member-application", memberApplicationData)
  }
  // #endregion


  // #region User
  public registerUser = (userWriteDTO: IUserWriteDTO): Promise<IUser> => {
    return this.post("/users", userWriteDTO)
  }

  public forgotPassword = (data: IPasswordResetRequest): Promise<void> => {
    return this.post("/users/reset-password", data)
  }

  public changeEmail = (data: IEmailChange): Promise<void> => {
    return this.post("/users/change-email", data)
  }

  public changePassword = (data: IPasswordChange): Promise<void> => {
    return this.post("/users/change-password", data)
  }

  public newPassword = (user: IUser, data: INewPasswordRequest): Promise<void> => {
    return this.post(user["@id"] + "/new-password", data)
  }

  public getUserObjectRoles = (userId: string): Promise<IHydraCollection<IUserObjectRole>> => {
    // @todo https://futureprojects.atlassian.net/browse/FCP-1448
    return this.get(replaceIdPlaceholder(entityEndpointList[EntityType.UserObjectRole].url, userId))
  }
  // #endregion

  // #region Verification
  public confirmAccountVerification = (data: IVerification): Promise<IOperationResultOutput> => {
    return this.post(`/account_verifications/${data.id}/confirm`, { 'token': data.token })
  }

  public confirmEmailChangeVerification = (data: IVerification): Promise<IOperationResultOutput> => {
    return this.post(`/email_change_verifications/${data.id}/confirm`, { 'token': data.token })
  }

  public confirmResetPasswordVerification = (data: IPasswordReset): Promise<IOperationResultOutput> => {
    return this.post(`/password_reset_verifications/${data.id}/confirm`, { 'token': data.token, 'password': data.password })
  }
  // #endregion

  // #region transition
  /**
   * Performes a state transition at the /transition endpoint of a given entity.
   *
   * NOTE: only supports the /transition endpoint currently
   *
   * @param entity entity to be transitioned
   * @param transitionInput data to be send to the transition endpoint
   * @returns the entity after the transition
   *
   * @template E type of the entity which should be transitioned
   * @template TransitionEnum transition to be performed on the entity
   */
  public transitionEntity = <E extends IModel, TransitionEnum>(entity: E, transitionInput: TransitionDTO<TransitionEnum>): Promise<E> => {
    return this.post(entity["@id"] + "/transition", { 'action': transitionInput.action, 'message': transitionInput.message })
  }
  // #endregion

  // #region Documents
  public createDocumentPdf = (projectDocument: IDocument): Promise<void> => {
    return this.post(projectDocument["@id"] + "/create-pdf", [])
  }

  public moveChapter = (moveChapter: IChapter, beforeChapter: IChapter): Promise<void> => {
    return this.post(moveChapter["@id"] + "/move", { beforeChapter: beforeChapter["@id"] })
  }
  // endregion

  // #region Meta-Data

  public getBackendCommit = async (): Promise<string> => {
    const response = await this.axios.request({ url: `/commit.txt` })
    return response.data as string
  }


  public getSysinfo = (): Promise<ISysinfo> => {
    return this.get(`/sysinfo`)
  }

  // #endregion

  // #region user object roles
  public updateUserProjectRole = (userProjectRole: IUserProjectRole): Promise<IUserProjectRole> => {
    return this.patch(userProjectRole["@id"], userProjectRole.metadata)
  }

  /**
   * Temporary helper function to transform type MembershipRole to UpdateMembershipRole.
   * @see UpdateMembershipRole for further informations
   * @see https://futureprojects.atlassian.net/browse/FCP-1786
   */
  private transformMemberShipRoleTypeToActionRequestUORType = (membershipRole: MembershipRole): UpdateMembershipRole => {
    switch (membershipRole) {
      case MembershipRole.Coordinator:
        return UpdateMembershipRole.Coordinator
      case MembershipRole.Planner:
        return UpdateMembershipRole.Planner
      case MembershipRole.Observer:
        return UpdateMembershipRole.Observer
      default: return undefined
    }
  }

  public changeRoleOfUserProjectRole = (userProjectRole: IUserProjectRole): Promise<IOperationResultOutput> => {
    return this.post(userProjectRole["@id"] + "/change-role", { role: this.transformMemberShipRoleTypeToActionRequestUORType(userProjectRole["@type"]) })
  }
  // #endregion

  // #region Action-Requests
  /*
   * @todo multi allow invitations and other action requests.
   * Note: Apparently not all ActionRequests will be acceptable, rejectable, deleteable,... The api has not yet implemented all action requests.
   * Difference between ProjectMemberApplication and Invitations is, an open invitation can be retrieved by an extra view endpoint.
   * Some ActionRequests provide general endpoints, e.g. BugReport has a general post and delete endpoint
   */

  /**
   * Accept an ActionRequest with a user message as reply.
   *
   * @param actionRequest
   * @param reply is the answer of the user, who accepts the ActionRequest
   * @param token is a unique identifier, which some ActionRequests require to exceute the accept operation
   * @returns the result
   */
  public acceptActionRequest = (actionRequest: IActionRequest, reply: IActionRequestReplyInput, token?: string): Promise<IOperationResultOutput> => {
    return this.post(actionRequest["@id"] + "/accept" + (token ? `/${token}` : ""), reply)
  }

  /**
   * Reject an ActionRequest with a user message as reply.
   *
   * @param actionRequest
   * @param reply is the answer of the user, who rejects the ActionRequest
   * @param token is a unique identifier, which some ActionRequests require to exceute the reject operation
   * @returns the result as IOperationResultOutput
   */
  public rejectActionRequest = (actionRequest: IActionRequest, reply: IActionRequestReplyInput, token?: string): Promise<IOperationResultOutput> => {
    return this.post(actionRequest["@id"] + "/reject" + (token ? `/${token}` : ""), reply)
  }

  /**
   * Withdraw an ActionRequest with a user message as reply.
   *
   * @param actionRequest
   * @param reply is the answer of the user, who withdraw the ActionRequest
   * @returns the result as IOperationResultOutput
   */
  public withdrawActionRequest = (actionRequest: IActionRequest, reply: IActionRequestReplyInput): Promise<IOperationResultOutput> => {
    return this.post(actionRequest["@id"] + "/withdraw", reply)
  }

  public deleteActionRequestBySender = (actionRequest: IActionRequest): Promise<IOperationResultOutput> => {
    return this.post(actionRequest["@id"] + "/delete-as-sender", {})
  }

  public deleteActionRequestByReceiver = (actionRequest: IActionRequest): Promise<IOperationResultOutput> => {
    return this.post(actionRequest["@id"] + "/delete-as-receiver", {})
  }
  // #endregion

  // #region special ActionRequests
  /**
   * Accepting a project member application with a user message as reply.
   *
   * @param actionRequest
   * @param reply is the answer of the user, who accepts the application
   */
  public acceptProjectMemberApplication = (actionRequest: IProjectMemberApplication, reply: IMemberRoleInput): Promise<IUserProjectRole> => {
    return this.post(actionRequest["@id"] + "/accept", reply)
  }
  // #endregion
  // #region IOIDCAPI

  public getOIDCProviders = (): Promise<INativeHydraCollection<IOIDCProvider>> => {
    return this.get(`/oidc_providers`)
  }

  public oidcLogin = (providerShortName: string, idToken: string, accessToken: string, termsAccepted: boolean): Promise<IAuthReply> => {
    return this.post(`/oidc_login`, { 'provider': providerShortName, idToken, accessToken, termsAccepted })
  }

  // #endregion

}

export default new ProjektfabrikClient(FCP_API_ENTRYPOINT)
