import { isOfEntityType } from "@api/entityTypeEndpointDefinitions"
import { IIdea, IModel, IMotivationAndSkills, INumericIdentifierModel, IProgram, IProject, IRI, ITenant, IUser } from "@api/schema"
import { StringBasedStringArray } from "@basics/common"
import { EntityType } from "@redux/common/reduxTypes"

import { Email } from "./common"

// #region Abstract ActionRequest

/**
 * ActionRequests are (most times) created by a user but probably reference
 * another entity, like programs or projects, as sender, and optionally another
 * entity as receiver of the request.
 * The receiver may also be an email address, in that case a token is created to
 * be sent in the invitation email containing a confirmation link. Clicking this
 * link will allow the user to register and be linked to the sending entity, e.g.
 * by creating an according UserObjectRole.
 *
 * Action requests can be simple, e.g. "abuse notifications" that are one-way:
 * created by a user but no longer visible to him, with a project|idea|... as
 * relatedEntity and a program or "system" as receiver, so no token is required
 * and deletedBy* fields are irrelevant.
 * Or they can be complex, so they use deletedBy* as both sides have a
 * "request inbox" to view outgoing/incoming requests and track their state.
 *
 * Note: ReceiverObject,RelatedEntityObject and SenderObject are set by the backend for the client's convenience
 * and to prevent the client from making dozens of requests for the sender
 * receiver / relatedObject when displaying a sender's/receiver's mailbox).
 *
 * @todo multi modulize ActionRequests
 * @see https://futureprojects.atlassian.net/browse/FCP-1875
 * @template ReceiverObject could be null or a stub, set by the backend
 * @template RelatedEntityObject could be null or a stub, set by the backend
 * @template SenderObject could be null or a stub, set by the backend
 * @template MetaData contains possible additional information of the action request
 * @template ActionRequestReceiver could contain the IRI of the ReceiverObject or is an email or marked as SystemMailboxType
 * @template ActionRequestRelatedEntity could contain the IRI of the RelatedEntityObject or could be an empty string
 * @template ActionRequestSender could contain the IRI of the SenderObject or marked as SystemMailboxType
 */
interface IAbstractActionRequest<
  ReceiverObject extends IModel,
  RelatedEntityObject extends IModel,
  SenderObject extends IModel,
  MetaData,
  ActionRequestReceiver extends IRI | Email | SystemMailboxType,
  ActionRequestRelatedEntity extends IRI | RelatedEntityObjectIsNullType,
  ActionRequestSender extends IRI | SystemMailboxType | AnonymousSender
// An action request can only be retrieved via Collection call, therefor detailResult in unnecessary.
> extends Omit<INumericIdentifierModel, 'usedPrivileges' | 'detailResult'> {
  readonly "@type": ActionRequestType
  readonly createdAt: Date | string
  readonly createdBy: IUser
  /** Only relevant for invitations. */
  expiresAt?: Date | string
  /** The message created by the maker/creator of the action request. */
  message: string
  metadata: MetaData
  /**
   * IRI of an entity this request addresses. E.g. when a project
   * wants to move to another program, that program's IRI will be
   * stored here. This way this request can be displayed in the
   * program's inbox. Can also contain 'system' instead of an IRI
   * to address the platform itself, e.g. Account- and
   * PlatformManagers. Can also contain an email address, for
   * requests that invite users to the platform.
   */
  readonly receiver: ActionRequestReceiver
  /**
   * The corresponding object of the receiver.
   */
  readonly receiverObject?: ReceiverObject
  /**
   * IRI of an entity this request references. E.g. when a user
   * reports a project, the project IRI will be stored here.
   */
  readonly relatedEntity: ActionRequestRelatedEntity
  readonly relatedEntityObject?: RelatedEntityObject
  readonly repliedAt?: Date | string
  /** The user, who accepts, rejects or withdraws the action request. */
  readonly repliedBy?: IUser
  /** The user, who accepts, rejects or withdraws the action request can add a message. */
  reply: string
  /**
   * IRI of a responsible Entity which initiated the request.
   * E.g. when a project wants to move to another program its IRI
   * is stored here, so it can see the request in its outbox. The
   * triggering user is not really relevant, he is stored in
   * createdBy. The sender may also be empty, e.g. for (anonymous)
   * abuse reports.
   */
  readonly sender: ActionRequestSender
  /**
   * The corresponding object of the sender.
   */
  readonly senderObject?: SenderObject
  /**
   * The state of the action request.
   */
  readonly state: ActionRequestState
}

// #endregion

// #region General ActionRequests

/**
 * A platform ActionRequest could of type BugReport, UserLockMessage or ManagerInvitation.
 * @todo multi maybe replace the general IPlatformActionRequest by the specific ActionRequests.
 *
 * @template RelatedEntityObjectType is a project, a program, a tenant, a user or an idea
 */
export type IPlatformActionRequest<
  RelatedEntityObjectType extends IProject | IProgram | ITenant | IUser | IIdea = IProject | IProgram | ITenant | IUser | IIdea
> = IAbstractActionRequest<
  never,
  RelatedEntityObjectType,
  never,
  StringBasedStringArray,
  SystemMailboxType,
  IRI | RelatedEntityObjectIsNullType,
  SystemMailboxType | AnonymousSender
>

/**
 * An ActionRequest-Type, which covers all ActionRequests
 * with a sender, receiver and/or a relatedEntity from an IModel.
 * @template IModel the ReceiverObject can be of type IModel
 * @template IModel the RelatedEntityObject can be of type IModel
 * @template IModel the SenderObject can be of type IModel
 * @template any Metadata can be any type
 * @template IRI | EMAIL is the unique IRI of the ReceiverObject or can be an email
 * @template IRI is the unique IRI of the RelatedEntityObject
 * @template IRI is the unique IRI of the SenderObject
 */
type IIModelActionRequest = IAbstractActionRequest<
  IModel,
  IModel,
  IModel,
  any,
  IRI | Email,
  IRI,
  IRI
>

// #region helper
/**
 * If the related entity object is null, the property 'relatedEntity' is an empty string.
 *
 * Notation is specified by the API.
 */
type RelatedEntityObjectIsNullType = ""

/**
 * The actual value representation of the RelatedEntityObjectIsNullType.
 *
 * Notation is specified by the API.
 */
export const RelatedEntityObjectIsNull: RelatedEntityObjectIsNullType = ""

/**
 * Type alias for defining an anonymous sender.
 */
type AnonymousSender = ""

/**
 * Each action request can have one the following states.
 *
 * Notation is specified by the API.
 */
export enum ActionRequestState {
  Open = 'open',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Withdrawn = 'withdrawn'
}

/**
 * An ActionRequestOperation defines an action on an ActionRequest.
 * Not every ActionRequest supports every operation.
 */
export enum ActionRequestOperation {
  /**
   * Accept an action request.
   */
  Accept = "accept",
  /**
   * Delete an action request.
   */
  Delete = "delete",
  /**
   * Reject an action request.
   */
  Reject = "reject",
  /**
   * Withdraw an action request.
   */
  Withdraw = "withdraw",
}

/**
 * Define all action request types.
 *
 * @todo multi add all types.
 *
 * Notation is specified by the API.
 */
export enum ActionRequestType {
  BugReport = "BugReport",
  ProjectMemberApplication = "ProjectMemberApplication"
}

/**
 * The system mailbox type is used to ensure, that e.g.
 * receiver or sender of a platform action request can be a system mailbox.
 *
 * Notation is specified by the API.
 */
export type SystemMailboxType = "system"

/**
 * The actual value representation of the SystemMailboxType.
 *
 * Notation is specified by the API.
 */
export const SystemMailbox: SystemMailboxType = "system"

// #endregion

// #region specific action requests
/**
 * This type represents a project member application, which has been sent to the project.
 * This application can be accepted, rejected, withdrawn, deleted by the receiver as well as
 * deleted by the sender.
 * This is an UserActionRequest as well as a ProjectActionRequest.
 *
 * To create a project membership application, the user has to send his motiviation and skills for the project.
 *
 * @template IProject is the ReceiverObject
 * @template IProject is the RelatedEntityObject
 * @template IUser is the SenderObject, who created and sent the member application
 * @template IMotivationAndSkills are the metadata of the member application
 * @template IRI is the unique IRI of the ReceiverObject(user)
 * @template IRI is the unique IRI of the RelatedEntityObject
 * @template IRI is the unique IRI of the SenderObject(user)
 */
export type IProjectMemberApplication = IAbstractActionRequest<IProject, IProject, IUser, IMotivationAndSkills, IRI, IRI, IRI>
// #endregion

// #region Uniontype
/**
 * Uniontype of all action requests.
 *
 * @todo multi add other action request (union type)
 */
export type IActionRequest = IProjectMemberApplication | IPlatformActionRequest

// #endregion

// #region filtered action request
/**
 * Base type for defining filtered ActionRequests-Types, e.g. an IUserActionRequest.
 * A filtered ActionRequest-Type is an IModel perspective at an ActionRequest.
 *
 * The property senderObject or receiverObject of the ActionRequest has to be from the specific IModel,
 * e.g. for an IUserActionRequest is the senderObject or the receiverObject of type IUser.
 * There is an exception for the UserActionRequest, who also allows additionally the receiver to be an Email.
 *
 * Note: PlatformActionRequests are not covered by this type.
 *
 * @todo multi when and where to use those types? As the ActionRequest is in development it could be a useful type to create certain components, where e.g.
 * only ActionRequests regarding challenges are allowed. Could be also useful to define general hooks.
 */
type IAbstractIModelActionRequest<
  AbstractActionRequest extends IIModelActionRequest,
  Model extends IModel
> = [
  Extract<Required<AbstractActionRequest>, Record<'senderObject', Model>> extends never ? "no" : "SenderIsOfModel",
  Extract<Required<AbstractActionRequest>, Record<'receiverObject', Model>> extends never ? "no" : "ReceiverIsOfAModel",
  // The following conditions are only relevant for UserActionRequests, b/c the receiver of an UserActionRequest could be an Email
  Model extends IUser ? "user" : "no",
  Extract<Required<AbstractActionRequest>, Record<'receiver', Email>> extends never ? "no" : "IsReceiverOfTypeEmail"
] extends infer ModelActionRequest
  ? (
    ModelActionRequest extends ["SenderIsOfModel", "no", "no", "no" | "IsReceiverOfTypeEmail"]
    ? AbstractActionRequest
    : ModelActionRequest extends ["no", "ReceiverIsOfAModel", "no", "no" | "IsReceiverOfTypeEmail"]
    ? AbstractActionRequest
    : ModelActionRequest extends ["no", "no", "user", "IsReceiverOfTypeEmail"]
    ? AbstractActionRequest
    : never
  )
  : never

/** Verifies that the given ActionRequest is a ProjectActionRequest. */
export type IProjectActionRequest<AbstractActionRequest extends IIModelActionRequest> = IAbstractIModelActionRequest<AbstractActionRequest, IProject>

/** Verifies that the given ActionRequest is a UserActionRequest. */
export type IUserActionRequest<AbstractActionRequest extends IIModelActionRequest> = IAbstractIModelActionRequest<AbstractActionRequest, IUser>

/** Verifies that the given ActionRequest is a ProgramActionRequest. */
export type IProgramActionRequest<AbstractActionRequest extends IIModelActionRequest> = IAbstractIModelActionRequest<AbstractActionRequest, IProgram>

// #endregion

// #region helper functions
/**
 * Verifies, if an actionRequest belongs to a certain entityType, which means the sender or the receiver
 * is from the given EntityType.
 * This is used when an ActionRequest was updated,
 * to reload all ActionRequests of an related entity that is of this EntityType.
 *
 * @see IAbstractIModelActionRequest in schema/action-requests for more informations about filtered ActionRequests.
 */
export const senderOrReceiverIsOfEntityType = (
  actionRequest: IActionRequest,
  entityType: EntityType.Challenge | EntityType.Project | EntityType.Program | EntityType.User
): boolean => {
  return isOfEntityType(actionRequest.receiverObject as IModel, entityType) || isOfEntityType(actionRequest.senderObject as IModel, entityType)
}

/**
 * Type guard function, which checks, if the ActionRequest is a platform ActionRequest.
 *
 * @todo multi as soon as ActionRequests exist, where the sender is a SystemMailbox (ManagerInvitations), use the ‘||’ condition.
 * Based on the current client implementation, typescript do not allow the or branch
 */
export const isPlatformActionRequest = (actionRequest: IActionRequest): actionRequest is IPlatformActionRequest =>
  actionRequest.receiver === SystemMailbox // || actionRequest.sender === SystemMailbox

/**
 * Type guard function, which checks, if the ActionRequest is a projectMemberApplication.
 */
export const isProjectMemberApplication = (actionRequest: IActionRequest): actionRequest is IProjectMemberApplication =>
  actionRequest["@type"] === ActionRequestType.ProjectMemberApplication
// #endregion