import { IRequestState } from "@modules/frontend-definitions/src"
import { selectSingleEntityUsecaseState } from "@redux/common/entityRequest/selectors"
import { AppState } from "@redux/reducer"
import { EntityType } from "@redux/common/reduxTypes"

import { UserAccountUsecases } from "./definitions"

/**
 * Returns an (single entity) IRequestState that contains the status of the request.
 * This special selector exists b/c pages that use forgotPasswordAction must not know the usecaseKey
 * that is internally used (UserAccountUsecases.ForgotPassword) - it's a "black box".
 */
export const selectForgotPasswordUsecaseState = (state: AppState): IRequestState =>
  selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.ForgotPassword) // usecaseKey matches the one in forgotPasswordAction/Saga

/**
 * Returns an (single entity) IRequestState that contains the status of the request.
 * This special selector exists b/c pages that use loadCurrentUserAction must not know the usecaseKey
 * that is internally used (UserAccountUsecases.LoadCurrentUser) - it's a "black box".
 */
export const selectLoadingCurrentUserUsecaseState = (state: AppState): IRequestState =>
  selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.LoadCurrentUser) // usecaseKey matches the one in loadCurrentUserAction/Saga


/**
 * Returns an (single entity) IRequestState that contains the status of the request.
 * This special selector exists b/c pages that use loginAction must not know the usecaseKey
 * that is internally used (UserAccountUsecases.Login) - it's a "black box".
 */
export const selectLoginUsecaseState = (state: AppState): IRequestState =>
  selectSingleEntityUsecaseState(state, EntityType.User, UserAccountUsecases.Login) // usecaseKey matches the one in loginAction/Saga
