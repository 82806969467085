import { Action } from "redux-saga"

import { IChapter, IDocument } from "@modules/documents/src/models/documents"

export enum DocumentUsecase {
  CreatePDF = "_usecase_create_document_pdf",
  MoveChapter = "_usecase_move_chapter"
}

/**
 * Action type for moving a chapter ahead of a different chapter.
 *
 * {@link moveChapterAction} for documentation
 */
export interface IMoveChapterAction extends Action<DocumentUsecase.MoveChapter> {
  reloadOnSuccess: () => void
  chapterToBeMoved: IChapter
  subsequentChapter: IChapter
}

/**
 * Action to move a chapter in front of a different chapter within the same document.
 *
 * @param chapterToBeMoved the chapter, which needs to be moved.
 * @param subsequentChapter the destination chapter, where the `moveChapter`will be put ahead of.
 * @param reloadOnSuccess will be called when the chapter was successfully moved.
 * @returns object of type  @see IMoveChapterAction
 */
export const moveChapterAction = (chapterToBeMoved: IChapter, subsequentChapter: IChapter, reloadOnSuccess: () => void): IMoveChapterAction => ({
  chapterToBeMoved,
  subsequentChapter,
  reloadOnSuccess,
  type: DocumentUsecase.MoveChapter
})

export interface ICreateDocumentPdfAction {
  document: IDocument
  type: DocumentUsecase.CreatePDF
}

export const createDocumentPdfAction = (document: IDocument): ICreateDocumentPdfAction => ({
  document,
  type: DocumentUsecase.CreatePDF,
})