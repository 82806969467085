import { IRequestState } from "@modules/frontend-definitions/src"
import { IFilteredCollectionState, emptyFilteredCollectionState } from "@redux/common/entityRequest/reducers"
import { LoadableEntityType, EntityType } from "@redux/common/reduxTypes"

import { AppState } from "../../reducer"

/**
 * shortcut to a IFilteredCollectionState of a collection use case
 *
 * @param state the AppState
 * @param entityType EntityType to be selected
 * @param usecaseKey use case for that EntityType to be selected
 * @returns the IFilteredCollectionState matching entitytype and usecaseKey
 */
export const selectCollectionUsecaseState = (state: AppState, entityType: LoadableEntityType, usecaseKey: string): IFilteredCollectionState => {
  const usecaseState = state.entityUsecases[entityType]?.filteredCollections?.[usecaseKey]

  return usecaseState ?? {
    // creating a copy instead of returning the global singleton
    ...emptyFilteredCollectionState
  }
}

/**
 * shortcut to a IRequestState of a single entity use case
 *
 * @param state the AppState
 * @param entityType EntityType to be selected
 * @param usecaseKey use case for that EntityType to be selected
 * @returns the IRequestState matching entitytype and usecaseKey
 */
export const selectSingleEntityUsecaseState = (state: AppState, entityType: EntityType, usecaseKey: string): IRequestState =>
  state.entityUsecases[entityType]?.singleEntities?.[usecaseKey]
