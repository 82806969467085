import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { IStatistics } from "@api/schema/statistics"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { ActionTypes } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"

import { IStatisticsApiRequestInvokingAction, statisticsRequestRunningAction, statisticsRequestSuccessAction } from "./actions"


/**
 * bundling all single sagas to be registered in ./index.ts
 *
 * Mapping Entity-specific Actions onto sagas, which call the Entity-specific API-endpoints
 */
export function* statisticsWatcherSaga(): any {
  yield all([
    takeEvery(ActionTypes.LoadStatistics, withCallback(loadStatisticsSaga)),

  ])
}


/**
 * generic saga to load statistics of type IStatistics
 *
 * @todo multi probably split into 2 sagas, global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
function* loadStatisticsSaga(action: IStatisticsApiRequestInvokingAction): Generator<any, IStatistics, any> {
  try {
    yield put(statisticsRequestRunningAction(action.usecaseKey))
    const statistics: IStatistics = yield call(apiClient.loadStatistics, action.usecaseKey, action.id)

    // signal to the requestreducer: the current type-request was successfull
    yield put(statisticsRequestSuccessAction(action.usecaseKey, statistics))

    return statistics
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("loadStatisticsSaga", errorMessage, action, err)

    // if an error occurred: signalize that the current type-request has failed with the error message
    yield put(statisticsRequestRunningAction(action.usecaseKey, errorMessage))

    return null
  }
}