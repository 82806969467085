import { captureException } from "@sentry/nextjs"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import client from "@api/client"
import { entityTypeFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IOperationResultOutput } from "@api/schema"
import { IStateChangeDTO } from "@api/schema-dto"
import { idFromIModelOrIRI } from "@basics/util-importless"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/common/entityRequest/actions"
import { loadModelAction } from "@redux/common/scopedObject/actions"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { SubmissionError } from "@services/submissionError"

import { ILockUnlockAction, LOCKED_API_RESULT, LockUsecases, UNLOCKED_API_RESULT, usecaseKeyForLockUnlockEntity } from "./definitions"
import { ILockableModel } from "./schema-definitions"


export function* lockOrUnlockWatcherSaga(): any {
  yield all([
    takeEvery(LockUsecases.LockEntity, withCallback(lockOrUnlockEntitySaga)),
    takeEvery(LockUsecases.UnlockEntity, withCallback(lockOrUnlockEntitySaga)),
  ])
}

/**
 * Saga to handle lock/unlock actions for lockable entities.
 * @returns the updated entity
 */
export function* lockOrUnlockEntitySaga(action: ILockUnlockAction): Generator<any, ILockableModel, any> {

  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}

  /**
   * Usecase key for this operation.
   * NOTE: must match the usecase key for the request selector within the corresponding hook.
   */
  const usecaseKey = usecaseKeyForLockUnlockEntity(action.entity, action.type)

  const entityType = entityTypeFromIModelOrIRI(action.entity)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    const stateChangeDto: IStateChangeDTO = {
      action: action.type, // NOTE: seems not to matter in case of locking/unlocking
      internalNote: action.internalNote,
      message: action.message
    }

    let result: IOperationResultOutput = null

    switch (action.type) {
      case LockUsecases.LockEntity:
        result = yield call(client.lockEntity, action.entity, stateChangeDto)
        if (result.result !== LOCKED_API_RESULT) {
          const err = new Error(`Locking an ${entityTypeFromIModelOrIRI(action.entity)} did not reply '${LOCKED_API_RESULT}' in API response, instead replied with '${result.result}'.`)
          captureException(err, { extra: { iri: action.entity["@id"] } })
        }
        break
      case LockUsecases.UnlockEntity:
        result = yield call(client.unlockEntity, action.entity, stateChangeDto)
        if (result.result !== UNLOCKED_API_RESULT) {
          const err = new Error(`Unlocking an ${entityTypeFromIModelOrIRI(action.entity)} did not reply '${UNLOCKED_API_RESULT}'  in API response, instead replied with '${result.result}'.`)
          captureException(err, { extra: { iri: action.entity["@id"] } })
        }
        break
    }

    // get the updated entity and replace it in the state
    const updatedEntity = yield putWait(loadModelAction(entityTypeFromIModelOrIRI(action.entity), idFromIModelOrIRI(action.entity)))
    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, updatedEntity))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, action.entity)
    }

    return action.entity
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("lockOrUnlockEntitySaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}