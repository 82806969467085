import { cloneDeep } from "lodash"
import { Action } from "redux-saga"

/**
 * Definition of an request state in the app's redux state.
 *
 * This interface defines the possible state of a request to the API:
 * It may (still) be loading or not, and there may be loadingErrors.
 */
export interface IRequestState {
  /** is the request currently loading? */
  isLoading: boolean
  /** error message if an error occured while the request */
  loadingError: string
  /**
   * was the request finished after it was started?
   * semantics not clear, see https://futureprojects.atlassian.net/browse/FCP-1304
   */
  loaded: boolean
}

/**
 * initial state based on IRequestState
 */
export const initialRequestState: IRequestState = {
  isLoading: false,
  loadingError: null,
  loaded: false,
}

/**
 * constant to be used when defining successful request state
 */
export const REQUEST_STATE_SUCCESSFUL: IRequestState = {
  loaded: true, // request is finished -> loaded!
  isLoading: false, // request is no longer loading
  loadingError: null, // there have been no errors
}

/**
 * Merges a list of request states to a single resulting request state.
 * Useful for hooks that dispatch more than one request and return a resulting request state.
 *
 * follows the rules:
 ** if one of the requests is loading the merged request is loading, except if one of the requests returns a loadingError
 ** if there is at least one request and all of the requests are loaded, the mergedRequest is loaded
 ** return the first existing error within the requests array as error for the merged request
 *
 * NOTE: If any request contains a loadingError, loading is returned as false, ignoring actual values.
 * This is to stay consistent with how Sagas handle multiple requests.
 *
 * NOTE: mergeRequestStates(undefined) or mergeRequestStates(null)
 * means the requests parameter is an array with one value: [undefined] or [null].
 * They are ignored when calculating the loaded state of the merged requests.
 *
 * @param requests
 * @see unit tests for full behaviour of possible input values
 * @returns a merged request state
 */
export const mergeRequestStates = (...requests: IRequestState[]): IRequestState => {
  // using a copy of initialRequestState, not a link, otherwise requests would write to the same object
  const mergedRequest: IRequestState = cloneDeep(initialRequestState)
  // return the first existing error within the requests array as error for the merged request
  mergedRequest.loadingError = requests.find(r => r?.loadingError)?.loadingError ?? null
  // if one of the requests is loading the merged request is loading
  mergedRequest.isLoading = (requests.some(r => r?.isLoading) ?? false) && !mergedRequest?.loadingError

  const definedRequests = requests.filter(r => !!r)
  mergedRequest.loaded = definedRequests.length > 0 && definedRequests.every(r => r.loaded)

  return mergedRequest
}

/**
 * A selector for `IRequestState`s, adapted to a certain `ScopeType` (`enum`).
 */
export type RequestStateSelector<ScopeType> = (scope: ScopeType) => (state: any) => IRequestState

/**
 * Collection of functions for request state handling, adapted to a certain `ScopeType` (of type `enum`).
 * - factory for "request tracking" actions to be dispatched
 * - utility functions for handling `IRequestState` objects
 * - selector for request states stored in the app state
 */
export interface IRequestStateAPI<ScopeType> {
  /** Creates an action that describes that a request has started. */
  taskStartedAction: (scope: ScopeType) => Action
  /** Creates an action that describes that a request succeeded successfully. */
  taskSucceededAction: (scope: ScopeType) => Action
  /** Creates an action that describes that a request has failed. */
  taskFailedAction: (scope: ScopeType, error: string) => Action
  /** Merge multiple request objects, for easier evaluation by request consumers. */
  mergeRequestStates: (...requestStates: IRequestState[]) => IRequestState
  /** Get the current request state from the host application's AppState. */
  selectRequestState: RequestStateSelector<ScopeType>
}
