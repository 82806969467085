import { captureException } from "@sentry/nextjs"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { platformIsInTestEnvironment } from "@basics/platform"
import { runsInBrowser } from "@basics/util-importless"
import DropdownComponent from "@components/common/DropdownComponent"
import Icon from "@components/common/Icon"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { DEFAULT_LOCALE } from "@locales/locales-config"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { getEffectiveLanguages } from "@services/util"


const usedNamespaces: NamespaceShortcut[] = []

/**
 * The component checks if there are more than 2 languages prepared:
 ** added in the locales (defined in i18n.js)
 *
 * If there's only DE and EN, you can switch German to English and vice versa.
 * If there's more than 2, the current language will be displayed and a dropdown menu will appear on hover.
 * If additional languages should be added: @see /doc/translations.md#sprach-dateien-übersetzen
 *
 * @todo when using more than 1 language, test the cookie-issue: https://github.com/vercel/next.js/issues/22375
 */
const LanguageSelector: React.FC = () => {
  const t = useDynamicTranslation()
  const router = useRouter()
  /** technically supported languages */
  const supportedLocales = router?.locales || [DEFAULT_LOCALE]
  const currentLocale = router?.locale || DEFAULT_LOCALE

  /** filtered languages: technically supported AND configured to be offered to the user */
  const resultingLanguages = getEffectiveLanguages(supportedLocales)

  // In test situations with more than 1 language and an unmocked asPath
  // the Link component may get an undefined href.
  if (platformIsInTestEnvironment() && router && !router.asPath && resultingLanguages.length > 1) {
    // eslint-disable-next-line no-console
    console.warn("router.asPath is not mocked and may result in 'Failed prop type: The prop `href` expects a `string` or `object` in `<Link>`, but got `undefined` instead.' ")
  }

  // sets a language preference cookie for 100 days. Value and expiration changes with each language change
  // @todo: Cookie must be mentioned in the data protection information
  useEffect(() => {
    const date = new Date()
    const expireMs = 100 * 24 * 60 * 60 * 1000 // 100 days
    date.setTime(date.getTime() + expireMs)
    // set cookie only, if more than one language is available
    if (runsInBrowser() && resultingLanguages.length > 1) {
      document.cookie = `NEXT_LOCALE=${currentLocale};expires=${date.toUTCString()};path=/`
    }
  }, [currentLocale])

  // should not occur: 0 languages
  if (resultingLanguages.length === 0) {
    captureException(new Error("LanguageSelector: no resulting language"))
    return null
  }

  // return early on "only 1 language"
  if (resultingLanguages.length === 1) {
    return null
  }

  // returns direct switch in case there's only 2 languages, probably the standard DE and EN
  if (resultingLanguages.length === 2) {
    return <div className="clickable">
      {resultingLanguages
        .filter((lang) => lang !== currentLocale)
        .map((lang) => (
          <Link key={lang} href={router.asPath} locale={lang}>
            <span id={lang} className="language-selector">
              <Icon name="world" size={25} />
              {t("default", "language." + lang)}
            </span >
          </Link >
        ))}
    </div>
  }
  // returns a list if there's more than 2 languages
  else {
    return <div>
      <DropdownComponent
        className={"language-selector__dropdown"}
        title={t("default", "language.change")}
        button={
          <span className="language-selector clickable">
            <Icon name="world" size={25} />
            {t("default", "language." + currentLocale)}
          </span>
        }
      >
        {resultingLanguages
          .filter((lang) => lang !== currentLocale)
          .map((lang) => (
            <Link
              href={router.asPath}
              locale={lang}
              key={lang}
              id={lang}
            >
              {t("default", "language." + lang)}
            </Link>
          ))}
      </DropdownComponent>
    </div>
  }
}

export default withDynamicNamespaces(LanguageSelector, usedNamespaces)
