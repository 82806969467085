import { ChallengeRole, INumericIdentifierModel, IRI, MembershipRole, ProgramRole, TenantRole, UserObjectRoleType, UserRole } from "@api/schema"
import { EntityType } from "@redux/common/reduxTypes"


/**
 * roles that belong to the active team of a project with reading rights
 */
export const TEAM_ROLES: MembershipRole[] = [
  MembershipRole.Coordinator,
  MembershipRole.Planner,
  MembershipRole.Observer,
]

/**
 * roles that belong to the inner team of a project with writing rights
 */
export const INNER_TEAM_ROLES: MembershipRole[] = [
  MembershipRole.Coordinator,
  MembershipRole.Planner,
]

/**
 * A role-entity connection that is required to get access to some function
 * if the user's UserObjectRoles match this requirements.
 */
export type RequiredObjectRole = {
  /** entity or IRI (=object) to which a role is defined */
  entity: INumericIdentifierModel | IRI
  /** type of the role to be attached to the entity */
  userObjectRoleType: UserObjectRoleType
}

/**
 * Specification of access restriction/permission.
 * Is usually interpreted with OR combination, when more than one entry is defined.
 *
 * To gain access the user must have at least one of the required definitions and none of the forbidden definitions.
 */
export interface AccessSpecification {
  /** one or more required user roles */
  requiredUserRole?: UserRole | UserRole[]
  /** one or more required user object roles independent from a specific object, @see UserObjectRoleType */
  requiredUOROnAnyObject?: UserObjectRoleType | UserObjectRoleType[]
  /** one or more specific user roles, who have no permission for the page, e.g. a platform manager has no permission to create a provider */
  forbiddenUserRole?: UserRole | UserRole[]
  /** one or more specific user object roles, who have no permission for the page, e.g. an account manager has no permission to create a provider */
  forbiddenUOROnAnyObject?: UserObjectRoleType | UserObjectRoleType[]
}

/**
 * Specification for access permission/restriction to a concrete element based on the role a user must have to an entity.
 * An element may be a html/navigation element or similar, that may be shown or clickable or not.
 *
 * To be differentiated from @see RouterPageAccessSpecification
 */
export interface RequiredObjectRoleOnEntity extends AccessSpecification {
  /** one or more required role a user must have onto an object */
  requiredObjectRole?: RequiredObjectRole | RequiredObjectRole[]
}

/**
 * Type of a general UserObjectRole (UOR) definition that will be transformed into a concrete entity UOR relation
 * by processing the identifier from a route (e.g. /projects/[slug]/team), combining it with the entityType to a real
 * entity and to be checked if the user has the given userObjectRole to that entity.
 */
interface RequiredRouteBasedUserObjectRole {
  /**
   * placeholder inside the route to calculate the identifier of the entity that is handled by the page
   *
   * NOTE: this name MUST match the property name of the entityType!
   */
  identifierParamName: "id" | "slug"
  /** entityType which is handled by the component; for which the identifier is used to identify the specific entity */
  entityType: EntityType
  /**
   * Role/roles that the user must have to the entity to gain access. Note: if more than one role is given,
   * the user must have ONE of the given roles.
   */
  userObjectRole: UserObjectRoleType[]
}

/**
 * specification for access permission/restriction to pages, where the specific entity is not known yet but
 * is calculated at runtime
 */
export interface RouterPageAccessSpecification extends AccessSpecification {
  /**
   * declaration of a required user object role for routes:
   * includes an identifier param name and the entity type instead the specific entity,
   * which must be calculated by the using function/component/hook
   */
  requiredRouteBasedUserObjectRole?: RequiredRouteBasedUserObjectRole
}

// #region pre defined access roles
export const PROJECT_TEAM_ACCESS: RouterPageAccessSpecification = {
  requiredRouteBasedUserObjectRole: {
    identifierParamName: "slug",
    entityType: EntityType.Project,
    userObjectRole: TEAM_ROLES
  }
}

export const PROJECT_INNER_TEAM_ACCESS: RouterPageAccessSpecification = {
  requiredRouteBasedUserObjectRole: {
    identifierParamName: "slug",
    entityType: EntityType.Project,
    userObjectRole: INNER_TEAM_ROLES
  }
}

export const LOGGED_IN_USERS_ACCESS: RouterPageAccessSpecification = {
  requiredUserRole: UserRole.User
}

// @todo multi: muss erweitert werden um Prüfung auf konkrete UOR, wenn die Route eine ID hat
export const TENANT_ACCESS: RouterPageAccessSpecification = {
  requiredUOROnAnyObject: [TenantRole.TenantManager]
}

// @todo multi: muss erweitert werden um Prüfung auf konkrete UOR, wenn die Route eine ID hat
export const PROGRAMM_AND_PLATFORM_ACCESS: RouterPageAccessSpecification = {
  requiredUOROnAnyObject: [TenantRole.TenantManager, TenantRole.Accountmanager, ProgramRole.CommunityManager, ChallengeRole.ChallengeManager],
  requiredUserRole: UserRole.PlatformManager
}

// @todo multi: muss erweitert werden um Prüfung auf konkrete UOR, wenn die Route eine ID hat
export const COMMUNITY_MANAGER_ACCESS: RouterPageAccessSpecification = {
  requiredUOROnAnyObject: [ProgramRole.CommunityManager]
}

// @todo multi: muss erweitert werden um Prüfung auf konkrete UOR, wenn die Route eine ID hat
export const CHALLENGE_MANAGER_ACCESS: RouterPageAccessSpecification = {
  requiredUOROnAnyObject: [ChallengeRole.ChallengeManager]
}

export const PLATFORM_MANAGER_ACCESS: RouterPageAccessSpecification = {
  requiredUserRole: UserRole.PlatformManager
}
// #endregion pre defined access roles
