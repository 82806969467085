import { VerificationActionTypes, VerificationActions } from "@redux/actions/verification"
import { ScopeTypes } from "@redux/common/reduxTypes"
import { REQUEST_PREFIX, SUCCESS_SUFFIX, IUsecaseRequestSuccessAction } from "@redux/common/scopedRequest/actions"

/**
 * All possible ActionTypes for a success request for all ScopeTypes
 */
type VerificationOperationRequestTypeSuccess = `${typeof REQUEST_PREFIX}${Uppercase<ScopeTypes.VerificationOperation>}${typeof SUCCESS_SUFFIX}`

/** reduces the state to a boolean, if a verification-operation was successful (e.g. verification of account, mail address change e.g.) */
export const verificationsReducer = (state = false, action: VerificationActions | IUsecaseRequestSuccessAction<boolean, VerificationOperationRequestTypeSuccess>): boolean => {
  switch (action.type) {
    case VerificationActionTypes.VerificationOperationSuccess:
      return (action as IUsecaseRequestSuccessAction<boolean>).result

    case VerificationActionTypes.ResetVerificationResult:
      return false

    default:
      return state
  }
}
