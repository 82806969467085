import { IUser, UserRole } from "@api/schema"
import { AuthActionTypes, IAuthState } from "@basics/auth"
import { hasMatches } from "@basics/util-importless"
import { AuthActions } from "@redux/actions/auth"
import { selectById } from "@redux/common/scopedObject/selectors"
import { AppState } from "@redux/reducer"
import { EntityType } from "@redux/common/reduxTypes"
import { emptyAuthState, timeDiff } from "@services/authHelper"


export const authReducer = (state: IAuthState = emptyAuthState, action: AuthActions): IAuthState => {
  switch (action.type) {
    case AuthActionTypes.SetJwt:
      return { ...state, ...action.state, initialized: true }

    // nothing to do, the logout saga clears the store completely
    // case AuthActionTypes.Logout:

    default:
      return state
  }
}

/**
 * Selector to retrieve the token for use in the Authentication Header.
 *
 * @returns the encoded JWT or null if none is in the store
 */
export const selectAuthToken = (state: AppState): string => state.auth.jwt

/**
 * Selector to retrieve the refresh token.
 *
 * @returns the token string or null if none is in the store
 */
export const selectRefreshToken = (state: AppState): string => state.auth.refreshToken

/**
 * Selector to retrieve the current username.
 *
 * @returns the username from the JWT or null if not authenticated
 */
export const selectUsername = (state: AppState): string => state.auth.username

/**
 * Selector to retrieve the current users ID.
 *
 * @returns the user ID from the JWT or null if not authenticated
 */
export const selectCurrentUserId = (state: AppState): number => state.auth.userId

/**
 * Selector to retrieve the current user roles.
 *
 * @returns array of role names, may be empty
 */
export const selectRoles = (state: AppState): UserRole[] => state.auth.roles

/**
 * Selector to check if the user has a given role.
 *
 * @returns true if the JWT claims the given role (or one of the given roles), else false
 */
export const selectHasRole = (state: AppState, role: UserRole | UserRole[]): boolean =>
  hasMatches(selectRoles(state), role)

/**
 * Selector to get the remaining seconds on the JWT TTL
 *
 * @returns number of seconds remaining (may be negative if already expired)
 */
export const selectAuthExpiresIn = (state: AppState): number =>
  state.auth.jwtExpiresAt ? timeDiff(state.auth.jwtExpiresAt) : 0

/**
 * Selector to get the remaining seconds on the refresh token TTL
 *
 * @returns number of seconds remaining (may be negative if already expired)
 */
export const selectRefreshTokenExpiresIn = (state: AppState): number =>
  state.auth.refreshTokenExpiresAt ? timeDiff(state.auth.refreshTokenExpiresAt) : 0

/**
 * Selector to check if a user is authenticated but the token expired.
 *
 * @returns true only if there is a token which is expired, else false
 */
export const selectAuthExpired = (state: AppState): boolean =>
  selectAuthExpiresIn(state) <= 0

/**
 * Selector to check if the refresh token expired.
 *
 * @returns true only if there is a refresh token which is expired, else false
 */
export const selectRefreshTokenExpired = (state: AppState): boolean =>
  selectRefreshTokenExpiresIn(state) <= 0

/**
 * Selector to check if the auth state is initialized (cookie parsed)
 *
 * @returns true if the auth state is already initialized
 */
export const selectIsAuthInitialized = (state: AppState): boolean => state.auth.initialized

/**
 * Selector to check if a user is authenticated.
 *
 * @returns true if there is a non-expired refresh token in the store, else false
 */
export const selectIsAuthenticated = (state: AppState): boolean =>
  !selectRefreshTokenExpired(state)

/**
 * Selector to retrieve the currently logged in user.
 *
 * @returns IUser, may be empty
 */
export const selectCurrentUser = (state: AppState): IUser => {
  const id = selectCurrentUserId(state)
  return id ? selectById(state, EntityType.User, id, true/* force detailResult for createdProjects etc*/) : null
}
