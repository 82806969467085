import { Action } from "redux"

import { IRI, ImplementationState, ProjectState, SDG } from "@api/schema"
import { ISortCriteria, OrderSpin } from "@api/schema/common"
import { IFilteredCollectionState } from "@redux/common/entityRequest/reducers"
import { ActionTypes, EntityType } from "@redux/common/reduxTypes"
import { ILoadCollectionByAction, ILoadCollectionPageAction, newLoadCollectionPageAction } from "@redux/common/scopedObject/actions"
import { IFilterCriteria } from "@redux/common/types"

/** *************************************************************************************************
 * This enum defines the usecases around the "projects marketplace".
 */
export enum MarketplaceUsecases {
  /**
   * load marketplace projects
   */
  LoadMarketplaceProjects = "_usecase_load_marketplace_projects",
  /**
   * set search parameters for marketplace - stores search params in the appstate (uses a reducer)
   */
  SetMarketSearchParams = "SET_MARKET_SEARCH_PARAMS",
}


export class MarketPlaceHelper {
  /**
   * Creates an ILoadCollectionByAction to trigger the asynchroneous loading of a collection of Objects,
   * filtered by given criteria.
   * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
   */
  public static createLoadFirstProjectsPageAction =
    (marketSearchParams: IProjectMarketSearchParams, programIRI: IRI, loadAll?: boolean): ILoadCollectionByAction => {
      const criteria = this.marketFilterCriteriaPreparationForLoadCollection(marketSearchParams)
      return {
        criteria,
        loadAll,
        usecaseKey: MarketplaceUsecases.LoadMarketplaceProjects,
        entityType: EntityType.Project,
        type: ActionTypes.LoadCollection,
        parentIri: programIRI
      } as ILoadCollectionByAction
    }

  /**
   * Creates an action to be dispatched to load the next page of an already loaded marketplace collection.
   *
   * @param lastState the IFilteredCollectionState that resulted in the last loading of a marketplace collection.
   * @returns an action to load the next page
   */
  public static createLoadNextProjectsPageAction = (lastState: IFilteredCollectionState): ILoadCollectionPageAction => {
    return newLoadCollectionPageAction(EntityType.Project, lastState?.nextLink, MarketplaceUsecases.LoadMarketplaceProjects)
  }

  /**
   * A function that builds the filter criteria for marketplace load filteredCollection action
   *
   * @returns the filter criteria
   */
  private static marketFilterCriteriaPreparationForLoadCollection = (searchParams: IProjectMarketSearchParams): IFilterCriteria => {

    const query: IFilterCriteria = {
      state: ProjectState.Active,
      implementationState: [],
    }

    if (searchParams?.implementationState?.length > 0) {
      query.implementationState = searchParams.implementationState
    }

    if (searchParams.pattern) {
      // Workaround for https://futureprojects.atlassian.net/browse/FCP-1376
      // b/c "uppercase umlaute" like 'Ü' are currently not supported by the api
      // adapted not the form itself,
      // because the user input should not be changed to avoid confusion
      query.pattern = searchParams.pattern.toLocaleLowerCase()
    }

    if (searchParams.sortBy) {
      query.order = { [searchParams.sortBy]: searchParams.sortOrder || "ASC" }
    }

    if (searchParams.programClassificationCode?.length > 0) {
      query.programClassificationCode = searchParams.programClassificationCode
    }

    if (searchParams.categories && searchParams.categories.length) {
      query.categories = searchParams.categories
    }

    if (searchParams.sdgs && searchParams.sdgs.length) {
      query.sdgs = searchParams.sdgs
    }

    return query
  }

  /**
   * Creates an Action to change the search params of the public project market place.
   * @param params new search params
   * @returns an Action to be dispatched
   */
  public static setProjectMarketSearchParamsAction = (params: IProjectMarketSearchParams): ISetMarketSearchParamsAction => ({
    params,
    type: MarketplaceUsecases.SetMarketSearchParams,
  })

}


// *************************************************************************************************
// #region set search parameters for marketplace
/**
 * Notation specified by the API.
 * Note: Be aware, the values of this enum are displayed in the exact order
 * defined by this enum within a dropdown menu, e.g. in the idea market.
 * @todo multi add categories (currently not needed/wanted by any customer)
 */
export enum IIdeaMarketSortType {
  CreatedAt = "createdAt"
}

/**
 * Notation specified by the API.
 * Note: Be aware, the values of this enum are displayed in the exact order
 * defined by this enum within a dropdown menu, e.g. in the project market.
 */
export enum IProjectMarketSortType {
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  Name = "name",
  MemberCount = "memberCount",
}

/**
 * This interface defines, what filter criteria are accepted by the API for ideas
 * @todo multi pattern: string könnte in ein eigenes ISearchPatternCriteria rübergehoben werden (und definiert in der MarketFormFields?)
 */
export interface IIdeaFilterCriteria extends IFilterCriteria {
  /** searches in description */
  pattern?: string
  /** order for created date */
  "order[createdAt]"?: OrderSpin
}

/**
 * This interface defines, what content from the user may be inputted into the search form.
 * It differs from data sent to the API, e.g.: the order[createdAt]-Data is sent to the API
 * as "order[createdAt]": 'ASC', while the user has to input this data as two elements.
 */
export interface IIdeaMarketSearchParams extends ISortCriteria<IIdeaMarketSortType> {
  pattern?: string
}

export interface ISDGCriteria {
  sdgs?: SDG[]
}

export interface ICategoryCriteria {
  categories?: number[]
}

export interface IProgramClassificationCodeCriteria {
  programClassificationCode?: number[]
}

/**
 * This interface defines, what content from the user may be inputted into the search form.
 * It differs from data sent to the API, e.g.: the order[createdAt]-Data is sent to the API
 * as "order[createdAt]": 'DESC', while the user has to input this data as two elements.
 */
export interface IProjectMarketSearchParams extends
  IProgramClassificationCodeCriteria,
  ISortCriteria<IProjectMarketSortType>,
  ISDGCriteria,
  ICategoryCriteria {
  pattern: string
  implementationState?: ImplementationState[]
}

/**
 * Action to trigger the (re)set of search-parameters for the Marketplace
 */
interface ISetMarketSearchParamsAction extends Action {
  params: IProjectMarketSearchParams
  type: MarketplaceUsecases.SetMarketSearchParams
}

/**
 * Empty search parameters for ideas.
 */
export const initialIdeaMarketSearchParams: IIdeaMarketSearchParams = {
  pattern: "",
  sortBy: IIdeaMarketSortType.CreatedAt,
  sortOrder: OrderSpin.Desc,
}

/**
 * Empty search parameters for projects.
 */
export const initialMarketSearchParams: IProjectMarketSearchParams = {
  categories: [],
  pattern: "",
  programClassificationCode: [],
  sdgs: [],
  sortBy: IProjectMarketSortType.CreatedAt,
  sortOrder: OrderSpin.Desc,
  implementationState: []
}

/**
 * Integrates changes in the marketplace search parameters
 */
export const marketPlaceSearchStateReducer =
  (state: IProjectMarketSearchParams = initialMarketSearchParams, action: ISetMarketSearchParamsAction): IProjectMarketSearchParams => {
    switch (action.type) {

      case MarketplaceUsecases.SetMarketSearchParams:
        const params = action.params
        return {
          ...state,
          categories: params.categories,
          implementationState: params.implementationState,
          sdgs: params.sdgs,
          pattern: params.pattern,
          programClassificationCode: params.programClassificationCode,
          sortBy: params.sortBy,
          sortOrder: params.sortOrder,
        }

      default:
        return state
    }
  }

// #endregion