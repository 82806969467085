import { Action } from "redux"

import { IStatistics } from "@api/schema/statistics"
import {
  ActionTypes,
  GeneralApiActionTypes,
  StatisticsType,
} from "@redux/common/reduxTypes"
import { IUsecaseRequestRunningAction, IUsecaseRequestSuccessAction } from "@redux/common/scopedRequest/actions"
import { IUsecaseReference } from "@redux/common/types"


/**
 * An IStatisticsApiRequestInvokingAction is an Action that leads to an API request
 * to call a statistics-endpoint defined in src/api/client.ts
 * It refers to the StatisticsType that this operation will run for,
 * and to an (optional) usecase that will be used to signal usecase completion (through usecaseRequestSuccessAction).
 *
 * @todo multi split into 2 actions, global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
export interface IStatisticsApiRequestInvokingAction extends Action<GeneralApiActionTypes.LoadStatistics>, IUsecaseReference {
  usecaseKey: StatisticsType
  id?: number
}

/**
 * Creates an IStatisticsApiRequestInvokingAction to trigger the asynchroneous loading of statistics,
 * chosen by given statisticsType.
 *
 * @todo multi split into 2 actions, global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
export const loadStatisticsAction =
  (statisticsType: StatisticsType, id?: number): IStatisticsApiRequestInvokingAction => ({
    type: ActionTypes.LoadStatistics,
    usecaseKey: statisticsType,
    id,
  })

/* ************************************************************************** */

export type StatisticRequestActions = IUsecaseRequestRunningAction<GeneralApiActionTypes.LoadStatisticsRunning>
  | IUsecaseRequestSuccessAction<IStatistics, GeneralApiActionTypes.LoadStatisticsSuccess>

/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request is running or has stopped
 * Creating this action without an error means the request ist running/loading.
 * With an error, the request is cancelled / not loading.
 *
 * @param usecaseKey usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const statisticsRequestRunningAction =
  (usecaseKey: StatisticsType, error: string = null): IUsecaseRequestRunningAction<GeneralApiActionTypes.LoadStatisticsRunning> => ({
    error,
    usecaseKey,
    type: ActionTypes.LoadStatisticsRunning
  })


/**
 * Creates an IUsecaseRequestRunningAction that signalises if a usecase request successful
 *
 * @param usecaseKey usecaseKey of the request
 * @param result result of the request,
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const statisticsRequestSuccessAction =
  (usecaseKey: StatisticsType, result: IStatistics): IUsecaseRequestSuccessAction<IStatistics, GeneralApiActionTypes.LoadStatisticsSuccess> => ({
    append: false,
    result,
    usecaseKey,
    type: ActionTypes.LoadStatisticsSuccess
  })
