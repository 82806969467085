import { all } from "redux-saga/effects"

import { oidcWatcherSaga } from "@modules/oidc/src"
import { dataWatcherSaga } from "@redux/common/scopedObject/sagas"
import { actionRequestWatcherSaga } from "@redux/usecases/actionRequests/saga"
import { challengesWatcherSaga } from "@redux/usecases/challengesForManagement"
import { classifyProjectWatcherSaga } from "@redux/usecases/classifyProject"
import { documentWatchersSaga } from "@redux/usecases/documents/sagas"
import { lockOrUnlockWatcherSaga } from "@redux/usecases/lock/saga"
import { onboardingWatcherSaga } from "@redux/usecases/onboarding/sagas"
import { projectStateWatcherSaga } from "@redux/usecases/projectState"
import { reportEntityWatcherSaga } from "@redux/usecases/report/saga"
import { statisticsWatcherSaga } from "@redux/usecases/statistics/sagas"
import { transitionWatcherSaga } from "@redux/usecases/transitions/saga"
import { userAccountWatcherSaga } from "@redux/usecases/userAccount/sagas"
import { userObjectRolesWatcherSaga } from "@redux/usecases/userObjectRoles/saga"

import { authWatcherSaga } from "./auth"
import { currentUserWatcherSaga } from "./currentUser"
import { feedbackWatcherSaga } from "./feedback"
import { ideaWatcherSaga } from "./idea"
import { myProjectsWatcherSaga } from "./myProjects"
import { platformWatcherSaga } from "./platform"
import { processesWatcherSaga } from "./processes"
import { projectWatcherSaga } from "./project"
import { registrationWatcherSaga } from "./registration"
// import { teamUploadsWatcherSaga } from "./teamUploads"
import { usersWatcherSaga } from "./users"
import { verificationWatcherSaga } from "./verification"

// ALL sagas must be registered here!

export function* rootSaga(): any {
  yield all([
    classifyProjectWatcherSaga(),
    actionRequestWatcherSaga(),
    authWatcherSaga(),
    currentUserWatcherSaga(),
    documentWatchersSaga(),
    challengesWatcherSaga(),
    feedbackWatcherSaga(),
    dataWatcherSaga(),
    myProjectsWatcherSaga(),
    ideaWatcherSaga(),
    lockOrUnlockWatcherSaga(),
    projectWatcherSaga(),
    onboardingWatcherSaga(),
    platformWatcherSaga(),
    projectStateWatcherSaga(),
    processesWatcherSaga(),
    reportEntityWatcherSaga(),
    registrationWatcherSaga(),
    statisticsWatcherSaga(),
    // teamUploadsWatcherSaga(),
    transitionWatcherSaga(),
    userAccountWatcherSaga(),
    userObjectRolesWatcherSaga(),
    usersWatcherSaga(),
    verificationWatcherSaga(),
    oidcWatcherSaga(),
  ])
}

