import { Action } from "redux"

import { IHydraCollection, INumericIdentifierModel } from "@api/schema"
import {
  ActionTypes,
  EntityType,
  GeneralApiActionTypes,
  IEntityTypeReference,
} from "@redux/common/reduxTypes"
import { IUsecaseReference } from "@redux/common/types"

/*
 * Action are central elements in Redux: "An Action is a plain object that represents the intention to change the state.
 * Actions are the only way to get data into the store. Any data ... needs to be dispatched as Actions."
 *
 * This collection of Actions can be used to keep track of requests regarding entities and entity collections.
 *
 * NOTE there is another variant of request tracking for non-entity requests, see `scopedRequest`.
 */


/* ****************************************************************************** */
/* Enum(erations) and types to standardize the usage of frequently used constants */
/* ****************************************************************************** */

// #region interfaces

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * A INewUsecaseRequestAction is an Action that has a IUsecaseReference and a IEntityTypeReference.
 * It represents the state of an usecase scoped request for a certain EntityType (mostly to the API).
 * Its attribute isCollection allows to inform about whether it is a Collection or a SingleModel usecase.
 * (Note/Todo: this is not clean architecture, but the only way to differentiate between two types b/c of Javascript)
 */
interface INewUsecaseRequestAction<ActionType extends string> extends Action<ActionType>, IUsecaseReference, IEntityTypeReference<EntityType> {
  isCollection: boolean
}

/**
 * A INewUsecaseRequestRunningAction extends INewUsecaseRequestAction by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 */
export interface INewUsecaseRequestRunningAction extends INewUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestRunning> {
  error: string
}

/**
 * An INewUsecaseRequestSuccessAction extends INewUsecaseRequestAction by results of a successful request.
 * It represents the state of an usecase scoped request (mostly to the API), that is finished.
 */
export interface INewUsecaseRequestSuccessAction<T> extends INewUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestSuccess> {
  /**
   * Result value of a successful request
   */
  result: T
}

/**
 * An INewSingleEntityUsecaseRequestAction extends INewUsecaseRequestAction to model that only SingleEntities are meant.
 * It's abstract and super interface of both Running as well as Success sub interfaces.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INewSingleEntityUsecaseRequestAction<ActionType extends string> extends INewUsecaseRequestAction<ActionType> { }

/**
 * A INewSingleEntityUsecaseRequestRunningAction extends INewUsecaseRequestRunningAction, INewSingleEntityUsecaseRequestAction
 * by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 */
interface NewSingleEntityUsecaseRequestRunningAction
  extends
  INewUsecaseRequestRunningAction,
  INewSingleEntityUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestRunning> {
}

/**
 * An INewSingleEntityUsecaseRequestSuccessAction extends INewUsecaseRequestSuccessAction, INewSingleEntityUsecaseRequestAction
 * by results of a successful request.
 * It represents the state of an usecase scoped request (mostly to the API), that is finished.
 */
interface INewSingleEntityUsecaseRequestSuccessAction<T extends INumericIdentifierModel>
  extends
  INewUsecaseRequestSuccessAction<T>,
  INewSingleEntityUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestSuccess> {
  /**
   * Result value of a successful request
   */
  result: T
}

/**
 * An INewFilteredCollectionUsecaseRequestAction extends INewUsecaseRequestAction to model that filteredCollections are meant.
 * It's abstract and super interface of both Running as well as Success sub interfaces.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INewFilteredCollectionUsecaseRequestAction<ActionType extends string> extends INewUsecaseRequestAction<ActionType> { }

/**
 * A INewFilteredCollectionUsecaseRequestRunningAction extends INewUsecaseRequestRunningAction, INewFilteredCollectionUsecaseRequestAction
 * by data about the loading state and possible errors.
 * It represents the state of an usecase scoped request (mostly to the API), that may still be running/loading or not and may
 * resulted in errors.
 */
interface NewFilteredCollectionUsecaseRequestRunningAction
  extends
  INewUsecaseRequestRunningAction,
  INewFilteredCollectionUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestRunning> {
}

/**
 * An INewSingleEntityUsecaseRequestSuccessAction extends INewUsecaseRequestSuccessAction, INewFilteredCollectionUsecaseRequestAction
 * by results of a successful request.
 * It represents the state of an usecase scoped request (mostly to the API), that is finished.
 * Its isPage flag informs about whether or not the result is coming from a follow-up page.
 */
export interface NewFilteredCollectionUsecaseRequestSuccessAction<T extends INumericIdentifierModel>
  extends
  INewUsecaseRequestSuccessAction<IHydraCollection<T>>,
  INewFilteredCollectionUsecaseRequestAction<GeneralApiActionTypes.NewUsecaseRequestSuccess> {
  /**
   * Result value of a successful request
   */
  result: IHydraCollection<T>
  isPage: boolean
}

// #endregion

// #region creator functions

/**
 * Creates INewUsecaseRequestAction instances that signal if a usecase request is running or has stopped
 *
 * @param entityType entityType of the entity
 * @param usecaseKey usecaseKey of the request
 * @param error are there any errors, that should be reported, e.g. when a request has failed and the signal is: stopped running with errors
 * @returns a IUsecaseRequestRunningAction to be dispatched
 */
export const newSingleEntityUsecaseRequestRunningAction =
  (entityType: EntityType, usecaseKey: string, error: string = null): NewSingleEntityUsecaseRequestRunningAction => ({
    entityType,
    error,
    usecaseKey,
    isCollection: false,
    type: ActionTypes.NewUsecaseRequestRunning,
  })

export const newSingleEntityUsecaseRequestSuccessAction =
  <T extends INumericIdentifierModel>(entityType: EntityType, usecaseKey: string, result: T): INewSingleEntityUsecaseRequestSuccessAction<T> => ({
    entityType,
    result,
    usecaseKey,
    isCollection: false,
    type: ActionTypes.NewUsecaseRequestSuccess,
  })

export const newFilteredCollectionUsecaseRequestRunningAction =
  (entityType: EntityType, usecaseKey: string, error: string = null): NewFilteredCollectionUsecaseRequestRunningAction => ({
    entityType,
    error,
    usecaseKey,
    isCollection: true,
    type: ActionTypes.NewUsecaseRequestRunning,
  })

export const newFilteredCollectionUsecaseRequestSuccessAction =
  <T extends INumericIdentifierModel>(entityType: EntityType, usecaseKey: string, result: IHydraCollection<T>, isPage = false): NewFilteredCollectionUsecaseRequestSuccessAction<T> => ({
    entityType,
    isCollection: true,
    isPage,
    result,
    type: ActionTypes.NewUsecaseRequestSuccess,
    usecaseKey,
  })

// #endregion
