import { IDerivedStatistics, IStatistics } from "@api/schema/statistics"
import { IRequestState, REQUEST_STATE_SUCCESSFUL } from "@modules/frontend-definitions/src"
import { ActionTypes, GlobalStatisticsType, SingleStatisticsType, StatisticsType } from "@redux/common/reduxTypes"

import {
  StatisticRequestActions
} from "./actions"


// @todo multi rename so that it's not confused with global vs single -> https://futureprojects.atlassian.net/browse/FCP-1703
interface IStatisticsSingleState<T extends IStatistics> extends IRequestState {
  // we cannot "merge" the data directly into IRequestState by extending the state with IStatistics
  // to access the properties directly, because there may be property name conflicts,
  // if a statistic property is named the same like an IRequestState property
  /** statistics data */
  data: T
}

/**
 * States for Statistics
 *
 * @todo multi maybe rename to "global" and introduce a second "single" state type -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
export type IStatisticsStates = {
  [key in StatisticsType]: IStatisticsState<key>
}

/**
 * Define a statistic state in dependence of the given StatisticType.
 *
 * @todo multi probably split into 2 types, global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
export type IStatisticsState<SType extends StatisticsType> = IStatisticsSingleState<IDerivedStatistics<SType>>

/**
 * empty IStatisticsStates for initial states
 * NOTE "export" only for TestState class
 *
 * @todo multi rename to "global" and find a solution for "single" variant -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
export const emptyStatisticsStates: IStatisticsStates = {
  [GlobalStatisticsType.Challenge]: null,
  [GlobalStatisticsType.Ideas]: null,
  [GlobalStatisticsType.Program]: null,
  [GlobalStatisticsType.Project]: null,
  [GlobalStatisticsType.Tenant]: null,
  [GlobalStatisticsType.User]: null,
  [SingleStatisticsType.Program]: null
}

/**
 * This reducer manages the statistics states
 * The state has the following structure:
 * [statisticsType]: IStatistics
 *
 * @param statisticsStates the IStatisticsStates object, as in AppState.statistics
 * @param action an INewUsecaseRequestSuccessAction after an api call
 * @returns a state
 */
export const statisticsReducer = (statisticsStates: IStatisticsStates = emptyStatisticsStates, action: StatisticRequestActions): IStatisticsStates => {

  switch (action.type) {
    // on LoadStatisticsRequestRunning the request state is stored
    case ActionTypes.LoadStatisticsRunning:
      const requestAction = action
      return {
        ...statisticsStates,
        [requestAction.usecaseKey]: {
          // update request data
          isLoading: !requestAction.error,
          loadingError: requestAction.error,
          loaded: false,
          // reset recent statistics data
          data: null,
        } as IStatisticsSingleState<IStatistics>
      }
    // on LoadStatisticsSuccess the incoming results are stored
    // and the request is marked as finished and successful
    case ActionTypes.LoadStatisticsSuccess:
      const successAction = action
      return {
        ...statisticsStates,
        [successAction.usecaseKey]: {
          // on success we expect: loading is finished, no error occured, data has been loaded
          ...REQUEST_STATE_SUCCESSFUL,
          // add received data
          data: successAction.result
        } as IStatisticsSingleState<IStatistics>
      }
  }

  return statisticsStates
}