import { combineReducers, Reducer } from "redux"

import {
  IAttachmentDefinition,
  ICategory,
  IChallenge,
  IChallengeConcretization,
  IDiscussion,
  IFeedbackInvitation,
  IFeedbackPost,
  IIdea,
  INumericIdentifierModel,
  IProgram,
  IProject,
  IProjectFollowership,
  IProjectMembership,
  IProposal,
  IProposalAttachment,
  IProvider,
  ISupportRequest,
  ITeamUpload,
  IUser,
  IUserObjectRole,
} from "@api/schema"
import { IActionRequest } from "@api/schema/action-requests"
import { IChapter, IDocument } from "@modules/documents/src/models/documents"
import { ActionTypes, EntityType, LoadableEntityType } from "@redux/common/reduxTypes"

import { IEntityApiRequestSuccessAction, ILoadCollectionSuccessAction, IModelCLUDOperationSuccessAction } from "./actions"

/*
 * defines an ObjectReducer (for the specific data of entities)
 */

export interface IIndexedCollectionState<T extends INumericIdentifierModel> {
  [id: number]: T
}

export const initialIndexedCollectionState: IIndexedCollectionState<INumericIdentifierModel> = {}


/**
 * This Reducer is a general Reducer for all Entity-Types and collections of those Entity-Types, when requesting
 * data of them from the API.
 *
 * It only handles model-SUCCESS-Actions, triggered by the (general)saga(s), after the request to the API was
 * successfull and the (new/updated) or loaded entity should be stored in the state or should be deleted from
 * the state. It does not handle request-success-actions!
 *
 * Is used in redux/reducer/data.ts
 *
 * @param entityType The EntityType to be handled.
 * @returns An updated Entity or an updated collection of those Entities after Update, Delete or collection-loading
 */
export const scopedObjectReducer = <T extends INumericIdentifierModel>(entityType: EntityType): (state: IIndexedCollectionState<T>, action: IEntityApiRequestSuccessAction) => IIndexedCollectionState<T> => {
  const objectReducer = (
    state: IIndexedCollectionState<T> = initialIndexedCollectionState as IIndexedCollectionState<T>,
    action: IEntityApiRequestSuccessAction,
  ) => {
    // first check if we're responsible; if action has an other entityType than what we're supposed to react on, skip
    if (entityType !== action.entityType) {
      return state
    }

    switch (action.type) {
      case ActionTypes.LoadSuccess:
      // no break
      case ActionTypes.CreateSuccess:
      // no break
      case ActionTypes.UpdateSuccess:
        const singleModel = (action as IModelCLUDOperationSuccessAction<T>).model
        return { ...state, [singleModel.id]: singleModel }

      case ActionTypes.DeleteSuccess:
        const deletedModel = (action as IModelCLUDOperationSuccessAction<T>).model
        const reduced: IIndexedCollectionState<T> = {}
        Object.values(state).forEach((value: T) => {
          if (value.id === deletedModel.id) { return }
          reduced[value.id] = value
        })
        return reduced

      case ActionTypes.LoadCollectionSuccess:
        const newCollection: IIndexedCollectionState<T> = {}
        const data = (action as ILoadCollectionSuccessAction<T>).collection
        data.member.forEach((element: T) => {
          newCollection[element.id] = element
        })
        return { ...state, ...newCollection }

      // necessary, because all reducers are called
      // => if an action is triggered, where this reducer is not responsible, ignore this action and return the previous state
      default:
        return state
    }
  }

  return objectReducer
}

/**
 * This type encapsulates all loadable EntityTypes plus some more, see comments in scopedObjectsCombinedReducer definitions for reasons.
 */
type StoredEntityType = LoadableEntityType | EntityType.ChallengeConcretization | EntityType.ProjectFollowership | EntityType.ProjectMembership

/**
 * This type lists all StoredEntityType and ensures that there will be a data reducer for each of them.
 */
type DataStateType = {
  [key in StoredEntityType]: IIndexedCollectionState<INumericIdentifierModel>
}

/**
 * Create object reducers for all entity types to prevent errors from typescript,
 * even if we don't need the reducer and cannot replace it with a fake reducer like (state) => state
 * NOTE the fields in this reducer are named EXACTLY like the value in EntityType.* according to the type parameter of scopedObjectReducer
 */
export const scopedObjectsCombinedReducer: Reducer<DataStateType, any, any> = combineReducers({
  [EntityType.ActionRequest]: scopedObjectReducer<IActionRequest>(EntityType.ActionRequest),
  [EntityType.AttachmentDefinition]: scopedObjectReducer<IAttachmentDefinition>(EntityType.AttachmentDefinition),
  [EntityType.Category]: scopedObjectReducer<ICategory>(EntityType.Category),
  [EntityType.Challenge]: scopedObjectReducer<IChallenge>(EntityType.Challenge),

  // NOTE in theory, challengeConcretization "loaded data" field is superfluous, since EntityType.ChallengeConcretization entities
  // are always loaded & accessed from/with the Fund/Challenge: state.data.challengeConcretization is never read.
  // BUT it must exist as a field, since we need EntityType.ChallengeConcretization to stay in EntityType enum to be able
  // to handle challengeConcretization's CUD actions.
  // Therefore, we must keep it here, and it must be fully typed.
  [EntityType.ChallengeConcretization]: scopedObjectReducer<IChallengeConcretization>(EntityType.ChallengeConcretization),

  [EntityType.Discussion]: scopedObjectReducer<IDiscussion>(EntityType.Discussion),
  [EntityType.Document]: scopedObjectReducer<IDocument>(EntityType.Document),
  [EntityType.Chapter]: scopedObjectReducer<IChapter>(EntityType.Chapter),
  [EntityType.Idea]: scopedObjectReducer<IIdea>(EntityType.Idea),
  [EntityType.FeedbackInvitation]: scopedObjectReducer<IFeedbackInvitation>(EntityType.FeedbackInvitation),
  [EntityType.FeedbackPost]: scopedObjectReducer<IFeedbackPost>(EntityType.FeedbackPost),
  [EntityType.Program]: scopedObjectReducer<IProgram>(EntityType.Program),
  [EntityType.Project]: scopedObjectReducer<IProject>(EntityType.Project),
  [EntityType.ProjectFollowership]: scopedObjectReducer<IProjectFollowership>(EntityType.ProjectFollowership),
  [EntityType.ProjectMembership]: scopedObjectReducer<IProjectMembership>(EntityType.ProjectMembership),
  [EntityType.Proposal]: scopedObjectReducer<IProposal>(EntityType.Proposal),
  [EntityType.ProposalAttachment]: scopedObjectReducer<IProposalAttachment>(EntityType.ProposalAttachment),
  [EntityType.Provider]: scopedObjectReducer<IProvider>(EntityType.Provider),
  [EntityType.SupportRequest]: scopedObjectReducer<ISupportRequest>(EntityType.SupportRequest),
  [EntityType.TeamUpload]: scopedObjectReducer<ITeamUpload>(EntityType.TeamUpload),
  [EntityType.User]: scopedObjectReducer<IUser>(EntityType.User),
  [EntityType.UserObjectRole]: scopedObjectReducer<IUserObjectRole>(EntityType.UserObjectRole),
})
