import has from "lodash/has"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { RequestErrorTranslations } from "@api/requestError"
import { IOperationResultOutput } from "@api/schema"
import { addNotificationAction } from "@redux/actions/notifications"
import { IConfirmAccountAction, IConfirmChangeEmailAction, IResetPasswordAction, VerificationActionTypes } from "@redux/actions/verification"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { ScopeTypes } from "@redux/common/reduxTypes"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { usecaseRequestRunningAction, usecaseRequestSuccessAction } from "@redux/common/scopedRequest/actions"
import { SubmissionError } from "@services/submissionError"

export function* verificationWatcherSaga(): any {
  yield all([
    takeLatest(VerificationActionTypes.ConfirmAccount, withCallback(confirmAccountSaga)),
    takeLatest(VerificationActionTypes.ConfirmChangeEmail, withCallback(confirmChangeEmailSaga)),
    takeLatest(VerificationActionTypes.ResetPassword, withCallback(confirmPasswordResetSaga)),
  ])
}

/**
 * all sagas return true if they are successful, but to mark the return value
 * this constant is used
 */
const OPERATION_SUCCESSFUL = true

/**
 * saga to confirm a fresh user account and its mail
 */
function* confirmAccountSaga(action: IConfirmAccountAction) {
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.VerificationOperation))

    // We do not evaluate the returned result.result string: https://futureprojects.atlassian.net/browse/FCP-1757
    const result: IOperationResultOutput = yield call(apiClient.confirmAccountVerification, action.data)
    yield put(addNotificationAction("message.account.verified", "success"))
    yield put(usecaseRequestSuccessAction(ScopeTypes.VerificationOperation, OPERATION_SUCCESSFUL))

    if (has(action, "actions.onSuccess")) {
      yield call(action.actions.onSuccess, result.metadata)
    }

    return OPERATION_SUCCESSFUL
  } catch (err) {
    if (err instanceof Error) {
      if (err.message === RequestErrorTranslations.NotFound.valueOf()) {
        err.message = "verification.account.notFound"
      }

      if (action.actions?.setErrors) {
        yield call(action.actions.setErrors, { error: err.message })
      }

      const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
      showErrorsInTestEnvironment("confirmAccountSaga", errorMessage, action, err)

      yield put(usecaseRequestRunningAction(ScopeTypes.VerificationOperation, err.message))

      if (action.actions?.setSubmitting) {
        yield call(action.actions.setSubmitting, false)
      }
    }

    return false
  }
}

/**
 * saga to confirm a change of the users mail-address of an existing account
 */
function* confirmChangeEmailSaga(action: IConfirmChangeEmailAction) {
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.VerificationOperation))

    // We do not evaluate the returned result.result string: https://futureprojects.atlassian.net/browse/FCP-1757
    const result: IOperationResultOutput = yield call(apiClient.confirmEmailChangeVerification, action.data)
    yield put(addNotificationAction("verification:message.emailChanged", "success"))
    yield put(usecaseRequestSuccessAction(ScopeTypes.VerificationOperation, OPERATION_SUCCESSFUL))

    if (has(action, "actions.onSuccess")) {
      yield call(action.actions.onSuccess, result.metadata)
    }

    return OPERATION_SUCCESSFUL
  } catch (err) {
    if (err instanceof Error) {
      if (err.message === RequestErrorTranslations.NotFound.valueOf()) {
        err.message = "verification.confirmEmail.notFound"
      }

      if (action.actions?.setErrors) {
        yield call(action.actions.setErrors, { error: err.message })
      }

      const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
      showErrorsInTestEnvironment("confirmChangeEmailSaga", errorMessage, action, err)

      yield put(usecaseRequestRunningAction(ScopeTypes.VerificationOperation, err.message))

      if (action.actions) {
        if (action.actions.setSubmitting) {
          yield call(action.actions.setSubmitting, false)
        }
      }
    }

    return false
  }
}

/**
 * saga to confirm to reset the password of a user
 * within a "forgot password"-process
 */
function* confirmPasswordResetSaga(action: IResetPasswordAction) {
  try {
    yield put(usecaseRequestRunningAction(ScopeTypes.VerificationOperation))

    // We do not evaluate the returned result.result string: https://futureprojects.atlassian.net/browse/FCP-1757
    const result: IOperationResultOutput = yield call(apiClient.confirmResetPasswordVerification, action.data)
    yield put(addNotificationAction("verification:message.passwordResetComplete", "success"))
    yield put(usecaseRequestSuccessAction(ScopeTypes.VerificationOperation, OPERATION_SUCCESSFUL))

    if (has(action, "actions.onSuccess")) {
      yield call(action.actions.onSuccess, result.metadata)
    }

    return OPERATION_SUCCESSFUL
  } catch (err) {
    if (err instanceof Error) {
      if (err instanceof SubmissionError) {
        yield call(action.actions.setErrors, err.errors)
      } else {
        if (err.message === RequestErrorTranslations.NotFound.valueOf()) {
          err.message = "verification.resetPassword.notFound"
        }
        yield call(action.actions.setErrors, { error: err.message })
      }

      const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
      showErrorsInTestEnvironment("confirmPasswordResetSaga", errorMessage, action, err)
      yield put(usecaseRequestRunningAction(ScopeTypes.VerificationOperation, errorMessage))
      if (action.actions.setSubmitting) {
        yield call(action.actions.setSubmitting, false)
      }
    }

    return null
  }
}
