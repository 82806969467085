import { Action } from "redux"

import { entityTypeFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IHydraCollection, IModel, INumericIdentifierModel, IRI, UserRole } from "@api/schema"
import { IFormikActions } from "@basics/form"
import {
  ActionTypes,
  CreateableEntityType,
  DeleteableEntityType,
  EntityType,
  GeneralApiActionTypes,
  IEntityTypeReference,
  LoadableEntityType,
  ScopeTypes,
  UpdateableEntityType,
  UploadType,
} from "@redux/common/reduxTypes"

import { IFilterContainer, IFilterCriteria, IUsecaseReference } from "../types"


/*
 * Action are central elements in Redux: "An Action is a plain object that represents the intention to change the state.
 * Actions are the only way to get data into the store. Any data ... needs to be dispatched as Actions."
 *
 * This collection of Actions can be used to trigger the loading and altering of data from/to the API.
 * It defines all needed basic Actions to load, create, update and delete Entities and Collection of Entities,
 * as well as file uploads.
 */

/* ****************************************************************************** */
/* Enum(erations) and types to standardize the usage of frequently used constants */
/* ****************************************************************************** */

/**
 * @returns the default usecase key for update actions on IModel entities
 */
export const usecaseKeyForUpdateModel = (model: IModel): string =>
  ActionTypes.Update + model?.["@id"]

/**
 * @returns the default usecase key for delete actions on IModel entities
 */
export const usecaseKeyForDeleteModel = (model: IModel): string =>
  ActionTypes.Delete + model?.["@id"]

// #region interfaces

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * An IEntityApiRequestInvokingAction is an Action that leads to an API request.
 * It refers to the EntityType that this operation will run for,
 * and to an (optional) usecase that will be used to signal usecase completion (through usecaseRequestSuccessAction).
 */
export interface IEntityApiRequestInvokingAction<E extends EntityType, ActionType extends string> extends Action<ActionType>, IUsecaseReference, IEntityTypeReference<E> {
  /**
   * IRI of a parent entity in case that sub resources are loaded or an action should be performed to a sub-endpoint
   * of the given parent IRI
   */
  parentIri?: IRI
}

/**
 * Defines all possible request types general api actions.
 * In difference to ModelRequestSuccessActionType this type of api action is triggered first and if the api
 * action was successful like "GeneralApiActionTypes.Create", afterwards an api action
 * of the type "GeneralApiActionTypes.CreateSuccess" will be triggered.
 * @see ModelRequestSuccessActionType
 */
type ModelRequestActionType =
  GeneralApiActionTypes.Create
  | GeneralApiActionTypes.Delete
  | GeneralApiActionTypes.Load
  | GeneralApiActionTypes.Update
  | GeneralApiActionTypes.Upload

/**
 * Defines all possible success request types for api actions.
 * @see ModelRequestActionType
 */
type ModelRequestSuccessActionType =
  GeneralApiActionTypes.CreateSuccess
  | GeneralApiActionTypes.DeleteSuccess
  | GeneralApiActionTypes.UpdateSuccess
  | GeneralApiActionTypes.LoadSuccess

/**
 * ModelOrCollectionRequestSuccessActionType contains all entity request success types and the success request type
 * for loading a collection.
 */
type ModelOrCollectionRequestSuccessActionType =
  ModelRequestSuccessActionType
  | GeneralApiActionTypes.LoadCollectionSuccess

/**
 * A ILoadSingleEntityByAction is an IEntityApiRequestInvokingAction that loads a single model element / entity.
 */
export interface ILoadSingleEntityByAction extends IFilterContainer, IEntityApiRequestInvokingAction<LoadableEntityType, GeneralApiActionTypes.Load> {
  /**
   * if neededUsedRole is set the api fetch result should have the neededUsedRole in its usedRoles property, otherwise the saga
   * should provide an error
   *
   * @todo multi: upgrade to usedPrivileges
   */
  neededUsedRole?: UserRole
}

/**
 * A ILoadCollectionByAction is an IEntityApiRequestInvokingAction that loads a collection of model elements / entities.
 */
export interface ILoadCollectionByAction extends IFilterContainer, IEntityApiRequestInvokingAction<LoadableEntityType, GeneralApiActionTypes.LoadCollection> {
  /**
   * should all pages of entities be loaded, to make sure all entities of the given criteria are in the state
   */
  loadAll: boolean
  /**
   * if neededUsedRole is set the api fetch result should have the neededUsedRole in its usedRoles property, otherwise the saga
   * should provide an error
   *
   * @todo multi: upgrade to usedPrivileges
   */
  neededUsedRole?: UserRole
}

/**
 * An LoadPageAction extends an EntityAction by an URL to be loaded, especially when loading next pages of entities.
 */
export interface ILoadCollectionPageAction extends IEntityApiRequestInvokingAction<LoadableEntityType, GeneralApiActionTypes.LoadCollectionPage> {
  url: string
}

/**
 * An IModelContainer is an interface that contains a Model<T> object.
 * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
 */
export interface IModelContainer<T extends IModel> {
  /** the specific entity for which the upload is performed */
  model: T
}


/**
 * An IEntityApiRequestSuccessAction is an Action that signals the successful completion of a previous API request.
 * It refers to the EntityType that this operation has been run for.
 */
export interface IEntityApiRequestSuccessAction<ActionType extends ModelOrCollectionRequestSuccessActionType = ModelOrCollectionRequestSuccessActionType> extends Action<ActionType>, IEntityTypeReference<EntityType> {
}

/**
 * An IModelOperationSuccessAction extends an IEntityApiRequestSuccessAction and is a IModelContainer.
 * This interface is used for CREATE, LOAD(_COLLECTION), UPDATE and DELETE operations.
 */
export interface IModelCLUDOperationSuccessAction<T extends IModel, ActionType extends ModelRequestSuccessActionType = ModelRequestSuccessActionType> extends IModelContainer<T>, IEntityApiRequestSuccessAction<ActionType> {
}

/**
 * Contains data of form callback actions that may be called by a saga.
 */
export interface ICallbackActionContainer {
  callbackActions?: IFormikActions
}

/**
 * An IModelRequestActionWithCallback models "common" API request actions that regard an entity and provide form callback functions.
 * It extends an IEntityApiRequestInvokingAction and is a IModelContainer and a ICallbackActionContainer.
 */
export interface IModelRequestActionWithCallback<T extends IModel, E extends EntityType, ActionType extends ModelRequestActionType> extends IModelContainer<T>, ICallbackActionContainer, IEntityApiRequestInvokingAction<E, ActionType> {
}

/**
 * An IModelCreateOperationInvokingAction is a IModelRequestActionWithCallback for createable entities.
 */
export type IModelCreateOperationInvokingAction<T extends IModel> = IModelRequestActionWithCallback<T, CreateableEntityType, GeneralApiActionTypes.Create>

/**
 * An IModelUpdateOperationInvokingAction is a IModelRequestActionWithCallback for updateable entities.
 */
export type IModelUpdateOperationInvokingAction<T extends IModel> = IModelRequestActionWithCallback<T, UpdateableEntityType, GeneralApiActionTypes.Update>

/**
 * An IModelDeleteOperationInvokingAction is a IModelRequestActionWithCallback for deletable entities.
 */
export type IModelDeleteOperationInvokingAction<T extends IModel> = IModelRequestActionWithCallback<T, DeleteableEntityType, GeneralApiActionTypes.Delete>

/**
 * A LoadCollectionSuccessAction extends an IEntityApiRequestSuccessAction by the collection of results, delivered by the API.
 * NOTE / @TODO we'd like to use INumericIdentifierModel instead of IModel, but IProjectMembership doesn't implement it
 */
export interface ILoadCollectionSuccessAction<T extends IModel> extends IEntityApiRequestSuccessAction<GeneralApiActionTypes.LoadCollectionSuccess> {
  collection: IHydraCollection<T>
}

// #endregion

// #region creator functions

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/*                                                                            */
/* all created actions have a distinct type, a reference to an entityType     */
/* and an additional usecaseKey to separate multiple actions                  */
/* ************************************************************************** */

/**
 * Creates an LoadByAction to trigger the asynchroneous loading of a single Object,
 * filtered by given entityType and id.
 * A loaded Object is added to the redux-store, from where it can be filtered by (e.g.) selectCollection or selectByID
 * Loading a single Object ensures, that all available data is loaded and not only basic-data (-> detailResult === true)
 *
 * usage-example:
 * const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
 * ({ loadFund: (id: number) => dispatch(loadModelAction(EntityType.Fund, id)) })
 *
 * NOTE: ProjectMembership may not be loaded this way, because the API does not allow it (and it does not have an ID)
 *
 * @param entityType has to be a LoadableEntityType
 * @param id id of the entity
 * @param usecaseKey if usecaseKey is omitted, a default usecaseKey is created.
 * @param neededUsedRole the role with which the fetched entity should be delivered, otherwise the saga should provide an error; optional
 */
export const loadModelAction =
  (entityType: LoadableEntityType, id: number, usecaseKey?: string, neededUsedRole?: UserRole): ILoadSingleEntityByAction => ({
    criteria: { id },
    entityType,
    usecaseKey: usecaseKey || id.toString(),
    neededUsedRole,
    type: ActionTypes.Load,
  })

/**
 * A constant to avoid using in-line strings and implicit argument names.
 */
export const SLUG_OR_ID = "slugOrId"

/**
 * Creates an LoadByAction to trigger the asynchroneous loading of a single Object,
 * filtered by given entityType and slug.
 * A loaded Object is added to the redux-store, from where it can be filtered by (e.g.) selectCollection or selectByID
 * Loading a single Object ensures, that all available data is loaded and not only basic-data (-> detailResult === true)
 *
 * usage-example:
 * const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
 * ({ loadFund: (slug: string) => dispatch(loadModelAction(EntityType.Fund, slug)) })
 *
 * @param entityType has to be a LoadableEntityType
 * @param slugOrId id or slug of the entity
 * @param usecaseKey if usecaseKey is omitted, a default usecaseKey is created.
 */
export const loadModelBySlugOrIdAction =
  (entityType: LoadableEntityType, slugOrId: string, usecaseKey?: string): ILoadSingleEntityByAction => ({
    criteria: { [SLUG_OR_ID]: slugOrId }, // NOTE using this constant here to make it explicit that this is a non-default criterium
    entityType,
    usecaseKey: usecaseKey || slugOrId,
    type: ActionTypes.Load, // LOAD_PREFIX + entityType.toUpperCase(),
  })


/**
 * Creates an IModelCUDOperationInvokingAction to trigger the creation of an Object of the given EntityType
 */
export const createModelAction =
  (entityType: CreateableEntityType, model: IModel, actions: IFormikActions, parentIri?: IRI, /* , usecaseKey?: string */): IModelCreateOperationInvokingAction<IModel> => ({
    callbackActions: actions,
    entityType,
    model,
    parentIri,
    // NOTE short cut, since we do not assume creating more than 1 object per entity at a time
    usecaseKey: ActionTypes.Create,
    type: ActionTypes.Create,
  })

/**
 * Creates an IModelCUDOperationInvokingAction to update an object of the given EntityType
 *
 * NOTE: usecase key must be calculated by usecaseKeyForUpdateModel(model: IModel)
 */
export const updateModelAction =
  (entityType: UpdateableEntityType, model: IModel, actions: IFormikActions): IModelUpdateOperationInvokingAction<IModel> => ({
    callbackActions: actions,
    entityType,
    model,
    usecaseKey: usecaseKeyForUpdateModel(model),
    type: ActionTypes.Update,
  })

/**
 * Creates an IModelCUDOperationInvokingAction to delete an object of the given EntityType
 */
export const deleteModelAction =
  (entityType: DeleteableEntityType, model: IModel, actions?: IFormikActions): IModelDeleteOperationInvokingAction<IModel> => ({
    callbackActions: actions,
    entityType,
    model,
    usecaseKey: usecaseKeyForDeleteModel(model),
    type: ActionTypes.Delete,
  })

/**
 * Creates an ILoadCollectionSuccessAction to signalize the successful loading of a Collection
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const loadCollectionSuccessAction =
  (entityType: EntityType, collection: IHydraCollection<IModel>): ILoadCollectionSuccessAction<IModel> => ({
    collection,
    entityType,
    type: ActionTypes.LoadCollectionSuccess, // LOAD_PREFIX + entityType.toUpperCase() + "_COLLECTION" + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful loading of a single entity
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const loadModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.LoadSuccess> => ({
    entityType,
    model,
    type: ActionTypes.LoadSuccess, // LOAD_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful creation of an object to the scopedObjectReducer
 *
 * Is used by a SAGA and (mainly) processed by a corresponding reducer
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 */
export const createModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.CreateSuccess> => ({
    entityType,
    model,
    type: ActionTypes.CreateSuccess, // CREATE_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful update of an object
 *
 * Is used by a SAGA and (mainly) processed by a corresponding reducer
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const updateModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.UpdateSuccess> => ({
    entityType,
    model,
    type: ActionTypes.UpdateSuccess, // UPDATE_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an IModelCLUDOperationSuccessAction to signalize the successful deletion of an object
 *
 * Is used by a SAGA and (mainly) processed by a corresponding reducer
 *
 * do not touch: is used by scopedEntityReducer to fill (app)state.data
 *
 */
export const deleteModelSuccessAction =
  (entityType: EntityType, model: IModel): IModelCLUDOperationSuccessAction<IModel, GeneralApiActionTypes.DeleteSuccess> => ({
    entityType,
    model,
    type: ActionTypes.DeleteSuccess, // DELETE_PREFIX + entityType.toUpperCase() + SUCCESS_SUFFIX,
  })

/**
 * Creates an ILoadCollectionByAction to trigger the asynchroneous loading of a collection of Objects,
 * filtered by given entityType and criteria, tagged with the given usecase.
 * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
 */
export const newLoadCollectionAction =
  (entityType: LoadableEntityType, criteria: IFilterCriteria = {}, usecaseKey: string, parentIri?: IRI, loadAll = false, neededUsedRole?: UserRole): ILoadCollectionByAction => ({
    criteria,
    entityType,
    usecaseKey,
    type: ActionTypes.LoadCollection,
    loadAll,
    parentIri,
    neededUsedRole,
  })

/**
 * Creates an ILoadCollectionPageAction to load a given page of a collection, encoded within the given url
 * This method matches the new INewUsecaseRequestAction/entityUsecaseReducer style
 */
export const newLoadCollectionPageAction =
  (entityType: LoadableEntityType, url: string, usecaseKey: string): ILoadCollectionPageAction => ({
    entityType,
    usecaseKey,
    type: ActionTypes.LoadCollectionPage,
    url,
  })

// #endregion

// #region file upload

/**
 * An IUploadApiRequestInvokingAction is an Action that leads to an file uploading API request.
 * Available api endpoints are defined in src/api/client.ts
 * It refers to the EntityType that the upload should be performed for.
 */
export interface IUploadApiRequestInvokingAction<T extends IModel> extends IModelRequestActionWithCallback<T, EntityType, GeneralApiActionTypes.Upload> {
  /** the file that should be uploaded */
  file: File
  /** the type of the upload must be a selection from UploadType */
  uploadType: UploadType
}

/**
 * Creates an IUploadApiRequestInvokingAction to trigger a saga to upload a file for an UploadType of an specific entity
 *
 * @param uploadType type of that upload
 * @param entity specific entity for which the upload should be performed
 * @param file file to be uploaded
 * @param actions formik form specific actions
 * @param usecaseKey a string as key for special use cases, otherwise a default value is set
 * @returns a IUploadApiRequestInvokingAction to be dispatched
 */
export const uploadFileAction =
  (uploadType: UploadType, entity: INumericIdentifierModel, file: File, actions: IFormikActions, usecaseKey = ScopeTypes.UploadFileOperation): IUploadApiRequestInvokingAction<INumericIdentifierModel> => ({
    callbackActions: actions,
    model: entity,
    entityType: entityTypeFromIModelOrIRI(entity),
    file,
    type: ActionTypes.Upload,
    uploadType,
    usecaseKey,
  })

// #endregion
