import { captureException } from "@sentry/nextjs"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import client from "@api/client"
import { entityTypeFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { INumericIdentifierModel, IOperationResultOutput } from "@api/schema"
import { UNKNOWN_REQUEST_ERROR } from "@redux/common/constants"
import { newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction } from "@redux/common/entityRequest/actions"
import { showErrorsInTestEnvironment } from "@redux/common/sagaErrorHelpers"
import { SubmissionError } from "@services/submissionError"

import { IReportEntityAction, REPORT_API_RESULT, ReportUsecases, usecaseKeyForReportEntity } from "./definitions"


export function* reportEntityWatcherSaga(): any {
  yield all([
    takeEvery(ReportUsecases.ReportEntity, withCallback(reportEntitySaga)),
  ])
}

/**
 * Saga to handle a report entity action for reportable entities.
 */
export function* reportEntitySaga(action: IReportEntityAction): Generator<any, INumericIdentifierModel, any> {

  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}

  /**
   * Usecase key for this operation.
   * NOTE: must match the usecase key for the request selector within the corresponding hook.
   */
  const usecaseKey = usecaseKeyForReportEntity(action.entity)

  const entityType = entityTypeFromIModelOrIRI(action.entity)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    const result: IOperationResultOutput = yield call(client.reportEntity, action.entity, action.abuseReport)
    if (result.result !== REPORT_API_RESULT) {
      const err = new Error(`Reporting an ${entityTypeFromIModelOrIRI(action.entity)} did not reply '${REPORT_API_RESULT}' in API response, instead replied with '${result.result}'.`)
      captureException(err, { extra: { iri: action.entity["@id"] } })
    }

    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, action.entity))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, action.entity)
    }

    return action.entity
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("reportEntitySaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}